import React, { useState, useRef, useEffect } from "react";
import "./PropertyPageSearchFilter.css";
import { Autocomplete, TextField } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IconButton from "@mui/material/IconButton";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import {
  filterPropertyData,
  setPropertyFilterDatas,
  setResetFilter,
  updateFilterDropdownData,
} from "../../../../../../store/propertiesStore";
import { setRefresh } from "../../../../../../store/usersStore";

function PropertyPageSearchFilter() {
  const dispatch = useDispatch();
  const wholeProperties = useSelector(
    (state) => state.propertiesStore.wholeProperty
  );
  const FilterDropDownData = useSelector(
    (state) => state.propertiesStore.FilterDropDownList
  );
  const FilterData = useSelector(
    (state) => state.propertiesStore.propertyFilterData
  );
  useEffect(() => {
    dispatch(updateFilterDropdownData());
  }, [wholeProperties]);

  console.log(FilterData, "FilterData");
  console.log(wholeProperties, "wholeProperties");


  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      fontSize: "15px !important",
      fontFamily: "Inter",
      width: 200,
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter",
      },
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
    },
  };
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  console.log(selectionRange, "selectionRange");

  const handleFieldClick = () => {
    togglePickerView("custom_date_picker_container");
  };

  const togglePickerView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  };
  window.addEventListener("click", function (e) {
    try {
      if (
        !document
          .getElementById("custom_date_picker_field")
          ?.contains(e.target) &&
        !document
          .getElementById("custom_date_picker_container")
          ?.contains(e.target)
      ) {
        const element = document.getElementById("custom_date_picker_container");
        if (element) {
          if ((element.style.display = "block")) {
            element.style.display = "";
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  });
  const initialValues = {
    type: FilterData.type,
    status: FilterData.status,
    bank: FilterData.bank,
    start_date: FilterData.start_date,
    end_date: FilterData.end_date,
    sale_status: FilterData.sale_status,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(setPropertyFilterDatas(values));
      dispatch(filterPropertyData());
      dispatch(setRefresh());
    },
  });
  const handleSelect = (ranges) => {
    const start_date = ranges.selection.startDate.toLocaleDateString();
    const end_date = ranges.selection.endDate.toLocaleDateString();
    formik.setValues({ ...formik.values, start_date, end_date });
    setSelectionRange(ranges.selection);
  };
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".filter_icon_mobile");
      const container = document.querySelector(".filter_field_container");
      const datePicker = document.querySelector(
        ".custom-date-picker.rdrDateRangePickerWrapper"
      );

      const isDatePickerOpen = datePicker && datePicker.style.display == "flex";

      if (
        !isDatePickerOpen &&
        !icon.contains(event.target) &&
        !container.contains(event.target)
      ) {
        const datePickerContainer = document.getElementById(
          "custom_date_picker_container"
        );
        if (datePickerContainer) {
          if (datePickerContainer.style.display !== "block") {
            setFilterOpen(false);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterOpen]);

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  }

  return (
    <div>
      <div className="filter_icon_mobile">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("vehicle_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>

      <div
        id="property_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div id="filter_field_container" className="filter_field_container">
          <form className="filter_tool_bar" onSubmit={formik.handleSubmit}>
            <div className="filter_tool_field_width">
              <Autocomplete
                name="type"
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.type}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("type", newValue ? newValue.label : "");
                }}
                value={
                  FilterDropDownData.type.find(
                    (type) => type.value === formik.values.type
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 14, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select type" />
                )}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.bank}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("bank", newValue ? newValue.label : "");
                }}
                value={
                  FilterDropDownData.bank.find(
                    (bank) => bank.value === formik.values.bank
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 14, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0 } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select bank" />
                )}
              />
            </div>
            <div>
              <div
                id="custom_date_picker_field"
                className="custom_property_date_picker_field"
                onClick={handleFieldClick}
              >
                <p style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {formik.values.start_date ? (
                      formik.values.start_date
                    ) : (
                      <span className="customPlaceholder">Start date </span>
                    )}
                  </div>
                  {"-"}                  <div>
                    {formik.values.end_date ? (
                      formik.values.end_date
                    ) : (
                      <span className="customPlaceholder">&nbsp;End date</span>
                    )}
                  </div>
                </p>
                <div>
                  {formik.values.start_date && formik.values.end_date && (
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "grey",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          formik.setValues({
                            ...formik.values,
                            start_date: null,
                            end_date: null,
                          });
                          setSelectionRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            key: "selection",
                          });
                        }}
                      >
                        <p>
                          <CloseIcon />
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                id={"custom_date_picker_container"}
                className="custom_date_picker_container"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  className="custom-date-picker"
                />
              </div>
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.status}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "status",
                    newValue ? newValue.label : ""
                  );
                }}
                value={
                  FilterDropDownData.status.find(
                    (status) => status.value === formik.values.status
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 14, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0 } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select status" />
                )}
              />
            </div>
            
            <button className="toolbar_Search_button" type="submit">
              Search
            </button>
            <button
              type="button"
              className="toolbar_Reset_button"
              onClick={() => {
                dispatch(setResetFilter());
                setSelectionRange({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                });
                formik.setValues({
                  ...formik.values,
                  type: null,
                  bank: null,
                  status: null,
                  start_date: null,
                  end_date: null,
                  sale_status: null,
                });
              }}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PropertyPageSearchFilter;
