import React, { useEffect, useRef ,useState} from "react";
import {
  Grid,
  Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./PropertySlide.css";
import { FaChevronRight } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { FaChevronLeft } from "react-icons/fa";
import {  useParams } from "react-router-dom";
import { ApiConfig } from "../../../../../../api/apiConfig"; 
import { Helpers } from "../../../../../../services/Helpers";
import dummyprop from "../../../../../../assets/prop dmmy.png"
import noimage from "../../../../../../assets/no image.png"


const Propertyslide = () => {
  const { id } = useParams();
  const cardListRef = useRef(null);
  const theme = useTheme();
  const [startIndex, setStartIndex] = React.useState(0);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [Propertydetails, SetPropertydetails] = useState([]);
  const filteredProperties = Propertydetails.filter((item) => item.filetype !== "pdf");
  const visibleItems = filteredProperties.slice(startIndex, startIndex + 4);
  const getPropertydetails = async () => {
    try {
        if (id) {
          const result = await ApiConfig.get(
            `/customer_portal/property/${id}/get_property_sale_details_by_id`
          );
          const { data } = result.data;
          SetPropertydetails(data.attachments	);
          // setSelectedMedia(data.Property_attachment[0]?.file ?? '');
          const initialMedia = data.attachments[0]?.file_path ?? "";
          setSelectedMedia(initialMedia);
          setIsVideo(Helpers.getUrlExtensions(initialMedia));
        }
      } catch (error) {
        console.log(error.message);
      }
  };
// Modify this logic because the image will not change when click on similar item
useEffect(() => {
    getPropertydetails();
}, [id]);
const scrollLeft = () => {
 
  if (cardListRef.current) {
    cardListRef.current.scrollBy({ left: -200, behavior: "smooth" });
  }
};

const scrollRight = () => {
  if (cardListRef.current) {
    cardListRef.current.scrollBy({ left: 200, behavior: "smooth" });
  }
};
const isXs = useMediaQuery(theme.breakpoints.only("xs"));
const isMd = useMediaQuery(theme.breakpoints.only("sm"));

// const handleNext = () => {
//   setStartIndex((prev) => Math.min(prev + 4, Propertydetails.length - 4));
// };


// const handleBack = () => {
//   setStartIndex((prev) => Math.max(prev - 4, 0));
// };



const handleImageClick = (file) => {
  setSelectedMedia(file);
  setIsVideo(Helpers.getUrlExtensions(file));
};

const handleNext = () => {
  const nextIndex = (selectedIndex + 1) % Propertydetails.length;
  updateMedia(nextIndex);
};

const handleBack = () => {
  const prevIndex =
    (selectedIndex - 1 + Propertydetails.length) % Propertydetails.length;
  updateMedia(prevIndex);
};

const updateMedia = (index) => {
  setSelectedIndex(index);
  const media = Propertydetails[index].file_path;
  setSelectedMedia(media);
  setIsVideo(Helpers.getUrlExtensions(media));
};
  return (
    <>
      {/* <div className="image_flex">
        {selectedMedia && isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "120%" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia ||noimage}
            alt="Selected media"
            style={{ width: "100%", height: "120%" }}
          />
        )}
      </div> */}
       <div className="image_flex" style={{ position: "relative" }}>
      {selectedMedia && isVideo ? (
        <video
          src={selectedMedia}
          controls
          style={{ width: "100%" }}
          autoPlay
        />
      ) : (
        <img
          src={selectedMedia || noimage}
          alt="Selected media"
          style={{ width: "100%",  }}
        />
      )}

      {/* Navigation Buttons */}
      <Stack
        direction="row"
        spacing={2}
        style={{
          position: "absolute",
          top: "115px",
          width: "100%",
          justifyContent: "space-between",
          padding: "0 10px",
          marginLeft: "-10px",
        }}
      >
        <div
          onClick={handleBack}
          className="Property_backbuttons"
          
        >
          {/* <FaChevronLeft /> */}
        </div>
        <div
          onClick={handleNext}
          className="Propright_buttons"
          // style={{
          //   backgroundColor: "rgba(255, 255, 255, 0.6)", // Light transparent white
          //   color: "#000",
          //   cursor: "pointer",
          //   padding: "10px",
          //   borderRadius: "50%",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          // }}
        >
          {/* <FaChevronRight /> */}
        </div>
      </Stack>
    </div>
      {/* <div className="image_mobile_flex">
        {isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "220px" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia ||noimage}
            alt="Vehicle"
            style={{ width: "100%", height: "90%" }}
          />
        )}
      </div>
      <div className="image_flex_ipad">
        {selectedMedia && isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "90%" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia ||noimage}
            alt="Selected media"
            style={{ width: "100%", height: "90%" }}
          />
        )}
      </div> */}
{/* {selectedMedia ?(
      <Grid item xs={12} style={{ display: isXs || isMd ? "none" : "block" }}>
        <div
          style={{
            height: "15%",
            width: "100%",
            display: "flex",
            justifyContent: "center", // Centering the content
            alignItems: "flex-start",
            flexDirection: "row",
            marginTop: "79px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <div>
              <div
                onClick={handleBack}
                className="vehicleslider_backbuttons"
                disabled={startIndex === 0}
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  cursor: startIndex === 0 ? "not-allowed" : "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  width: "30px",
                }}
              >
               
                  <FaChevronLeft />
              
              </div>
            </div>
            <Grid
              container
              spacing={2}
              style={{ width: visibleItems.length * 25 + "%", marginLeft: "0" }}
            >
              {visibleItems.map((item, index) => (
                <Grid item xs={12 / visibleItems.length} key={index} mt={3}>
                  {Helpers.getUrlExtensions(item.file) ? (
                    <img
                      onClick={() => handleImageClick(item.file)}
                      src={dummyprop} // Assuming it's a preview image or a thumbnail
                      style={{
                        height: 150,
                        width: "100%",
                        display: "block",
                        overflow: "hidden",
                        cursor: "pointer",
                        marginTop: 14,
                      }}
                      alt={item.title}
                    />
                  ) : (
                    <img
                      onClick={() => handleImageClick(item.file)}
                      src={item.file}
                      style={{
                        height: 130,
                        width: "100%",
                        display: "block",
                        overflow: "hidden",
                        cursor: "pointer",
                        marginTop: 22,
                      }}
                      alt={item.title}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <div>
              <div
                onClick={handleNext}
                className="vehicleslider_buttons"
                disabled={startIndex >= filteredProperties.length - 4}
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  cursor:
                    startIndex >= filteredProperties.length - 4
                      ? "not-allowed"
                      : "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  width: "30px",
                }}
              >
                
                  <FaChevronRight style={{ marginTop: "23px" }} />
              
              </div>
            </div>
          </Stack>
        </div>
      </Grid>) :(null)} */}
      {/* {selectedMedia ?(
      <div style={{  display: isXs || isMd ? "flex" : "none", }} className="slider_container">
               <div
                onClick={scrollLeft}
                className="propertylider_backbuttonsss"
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaChevronLeft style={{ marginTop: "5px" }} />
              </div>
              <div style={{display:'flex',justifyContent:'start',gap:'30px',overflowX:'hidden','scrollBehavior':'smooth'}} ref={cardListRef}>
              {visibleItems
                .map((item, index) => (
                   <>
                    {Helpers.getUrlExtensions(item.file) ? (
                      <img
                        onClick={() => handleImageClick(item.file)}
                        src={dummyprop} // Replace with a placeholder or default image for videos
                        style={{
                          height: "90px", // Ensure a consistent image size
                          width: "150px",
                          cursor: "pointer",
                          margin: "auto", // Center the image itself within its container
                        }}
                        alt={item.title}
                      />
                    ) : (
                      <img
                        onClick={() => handleImageClick(item.file)}
                        src={item.file}
                        style={{
                          height: "80px", // Ensure a consistent image size
                          width: "180px",
                          cursor: "pointer",
                          margin: "auto", // Center the image itself within its container
                        }}
                        alt={item.title}
                      />
                    )}
                  </>
                ))}
            </div>

              <div
                onClick={scrollRight}
                className="propertylider_backbuttonsss"
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaChevronRight style={{ marginTop: "5px" }} />
              </div>
      </div>) :(null)} */}
    </>
  );
};

export default Propertyslide;
