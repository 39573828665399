import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPropertiesBasedOnLoanApplication,
  getPropertyBasedOnLoanApplicationFilter,
} from "../../../../../../api/apiServices/customerPortalApis";

import CustomChip from "../../../../../../components/customChip/CustomChip";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import PropertyLoanFilter from "./property_loan_filter/PropertyLoanFilter";
import usePagination from "../../../../../../components/Pagination";
import { setRefresh } from "../../../../../../store/usersStore";
import SimpleTable from "../../../../../../components/simpleTable/SimpleTable3";

function PropertyLoanApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PropertyLists, SetPropertyLists] = useState([]);
  const [isFiltered, setFiltered] = useState(false);
  const tableId = "PropertyLoanApplication"; // Unique identifier for the table
  const [currentPage, setCurrentPage, pageCount, SetPageCount] = usePagination(
    1,
    tableId
  );
  const [values, setValues] = useState({
    property_id: "",
    title: "",
    property_type: "",
    location: "",
    amount: "",
  });
  const hasAnyValue = (obj) => {
    return Object.values(obj).some((value) => value !== "");
  };
  useEffect(() => {
    if (hasAnyValue(values)) {
      dispatch(getPropertyBasedOnLoanApplicationFilter(currentPage, 10, values))
        .then((response) => {
          const { propertyLists, count } = response;
          SetPageCount(Math.ceil(count / 10));
          SetPropertyLists(propertyLists);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    } else {
      dispatch(getPropertiesBasedOnLoanApplication(currentPage, 10))
        .then((response) => {
          const { propertyLists, count } = response;
          SetPageCount(Math.ceil(count / 10));
          SetPropertyLists(propertyLists);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    dispatch(setRefresh())
  }, [currentPage, isFiltered]);

  
  const Columns = [
    {
      title: "#",
      dataIndex: "Key",
      key: "key",
    },
    {
      title: "Property ID",
      dataIndex: "property_id",
      key: "property_id",
    },
    {
      title: "Title",
      dataIndex: "sale_title",
      key: "sale_title",
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      key: "property_type",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="user_action_chipset">
          <CustomChip
            text=" Applications"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/${
                  row.property_id
                }/property_loan_applications`
              );
            }}
          />
          <CustomChip
            text=" Details"
            bgColor="#1976d2"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                  row.property_id
                }/update_properties?tk=0&view_edit=true`
              );
            }}
          />
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      PropertyLists &&
      PropertyLists.map((item, index) => {
        return {
          Key: currentPage * 10 - (10 - (index + 1)),
          property_id: item.pd_id	,
          sale_title: item.pd_name,
          property_type: item.ptl_type	,
          location: item.pd_location,
          amount: item.pd_sale_price,
        };
      });
    return result;
  };
 
  
  const onchangeValues = async (event) => {
    const { value, name } = event.target;
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    const isSpanish = currentLang === "es";
    setValues({ ...values, [name]: value });
  
  
  };
  ;
  const onResetValues = () => {
    setValues({
      property_id: "",
      title: "",
      property_type: "",
      location: "",
      amount: "",
    });
    setCurrentPage(1);
    setFiltered(!isFiltered);
  };

  const onSearch = () => {
    setCurrentPage(1);
    setFiltered(!isFiltered);
  };

  
  
  return (
    <div>
      <div style={{ margin: "30px 0px" }}>
        <PropertyLoanFilter
          values={values}
          onChange={onchangeValues}
          onReset={onResetValues}
          onSubmit={onSearch}
        />
      </div>
      <SimpleTable columns={Columns} rows={datas()} />
     
    </div>
  );
}

export default PropertyLoanApplication;
