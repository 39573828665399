import React, { useEffect, useState } from "react";
import FormButton from "../../../../../../../../components/formButton/FormButton";
import { Avatar, IconButton, InputBase, Paper, Button } from "@mui/material";
import CustomTextField from "../../../../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../../../../components/customTextArea/CustomTextArea";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import * as Yup from "yup";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "./bankFormStyle.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDispatch } from "react-redux";
import { FaUpload } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import { Helpers } from "../../../../../../../../services/Helpers";
import {
  CreateNewBanks,
  getApplicationOwnerNameById,
  updateBankDetails,
} from "../../../../../../../../api/apiServices/applicationOwnerApi";
import ResponseHandler from "../../../../../../../../services/ResponseHandler";
import { useParams } from "react-router";
import { RiFileEditFill } from "react-icons/ri";
import { ErrorToast } from "../../../../../../../../toasts/Toasts";

function BankForm() {
  const { Bank_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isView, SetIsView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    if (Bank_id) {
      SetIsView(true);
      dispatch(getApplicationOwnerNameById(Bank_id))
        .then((response) => {
          setFormikData(response);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [Bank_id]);
  const initialValues = {
    name: "",
    branch_code: "",
    email_id: "",
    address: "",
    phone_number: "",
    location: "",
    bank_type: "",
    about: "",
    file: null,
  };
  console.log(selectedImage, "selectedImage");

  const validationSchema = Yup.object({
    name: Yup.string().required("Bank name is required"),
    branch_code: Yup.string().required("Branch Code is required"),
    bank_type: Yup.string().required("Bank type is required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    address: Yup.string().required("Address is required"),
    phone_number: Yup.string().required("Phone Number is required"),
    location: Yup.string().required("Location is required"),
    about: Yup.string().required("About  is required"),
  });
  console.log(selectedImage, "selectedImage");

  let formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(" im called");
      
      if(selectedImage==null){
        ErrorToast("Upload Bank Logo")
        return;
      }
      const formData = new FormData();
      let isExistingAttachment = false;

      Object.keys(values).forEach((key) => {
        if (key !== "file") {
          formData.append(key, values[key]);
        }
      });
      console.log(formData, "formData3");

      if (
        selectedImage &&
        (selectedImage.toString().startsWith("http://") ||
          selectedImage.toString().startsWith("https://"))
      ) {
        isExistingAttachment = true;
      } else if (selectedImage) {
        formData.append("bankLogo", selectedImage);
        isExistingAttachment = false;
      }
      if (Bank_id) {
        formData.append("existing_attachment", isExistingAttachment);
        dispatch(updateBankDetails(Bank_id, formData))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      } else {
        dispatch(CreateNewBanks(formData))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
        console.log(formData, "ggg");
      }
    },
  });

  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `aonl_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
    setSelectedImage(data.aonl_logo);
  }
  return (
    <div>
      <div className="bank_form_header">
        <p className="page_title">
          {Bank_id
            ? isView
              ? "View Bank details"
              : "Update Bank Details"
            : "Add Bank"}
        </p>
        <div
          className="bank_form_back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <div>
        <div className="bank_form_main_Container">
          {Bank_id && isView && (
            <div
              className="bank_form_edit_details"
              onClick={() => SetIsView(false)}
            >
              <RiFileEditFill />
              <p>Edit</p>
            </div>
          )}
          <div className="bank_formContainer">
            <p className="bank_form_legend">
              {Bank_id ? "Bank Details" : "Add bank details"}
            </p>

            <form id="bankform" onSubmit={formik.handleSubmit}>
              <div className="BankForm_Top_section">
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  position: "relative",
                  flexDirection: "column",
                }}
              >
                <p className="bank_form_label">Bank Logo</p>
                <Avatar
                  alt="Bank Logo"
                  src={
                    selectedImage
                      ? selectedImage.toString().startsWith("http://") ||
                        selectedImage.toString().startsWith("https://")
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                      : null
                  }
                  sx={{
                    width: 180,
                    height: 180,
                    marginRight: "10px",
                    marginTop: "12px",
                  }}
                />
                {!isView && (
                  <div className="Pic_uploader">
                    <IconButton
                      component="label"
                      sx={{
                        position: "absolute",
                        top: "89px",
                         right:"-50px",// Adjust this value as needed
                        transform: "translateY(-50%)",
                        padding: 0.5,
                        background: "white",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        "&:hover": {
                          background: "white",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          color: "black",
                        }}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setSelectedImage(file);
                          // You might want to validate the file type/size here
                        }}
                      />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        id="file-input"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setSelectedImage(file);
                          // You might want to validate the file type/size here
                        }}
                        style={{ display: "none" }}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className="Bank_Inputfields_Form">
                <div className="bank_form_row">
                  <div>
                    <p className="bank_form_label">Bank Name</p>
                    <CustomTextField
                      disabled={isView}
                      name="name"
                      placeholder={"Enter bank name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                  <div>
                    <p className="bank_form_label">Branch Code</p>
                    <CustomTextField
                      disabled={isView}
                      name="branch_code"
                      placeholder={"Enter branch code"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.branch_code}
                      error={
                        formik.touched.branch_code &&
                        Boolean(formik.errors.branch_code)
                      }
                      helperText={
                        formik.touched.branch_code && formik.errors.branch_code
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                </div>
                <div className="bank_form_row">
                  <div>
                    <p className="bank_form_label">Email Address</p>
                    <CustomTextField
                      disabled={isView}
                      name="email_id"
                      placeholder={"Enter email address"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email_id}
                      error={
                        formik.touched.email_id &&
                        Boolean(formik.errors.email_id)
                      }
                      helperText={
                        formik.touched.email_id && formik.errors.email_id
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                  <div>
                    <p className="bank_form_label">Phone Number</p>
                    <CustomTextField
                      disabled={isView}
                      name="phone_number"
                      placeholder={"Enter phone number"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number}
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                </div>
                <div className="bank_form_row">
                  <div>
                    <p className="bank_form_label">Location</p>
                    <CustomTextField
                      disabled={isView}
                      name="location"
                      placeholder={"Enter location"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                  <div>
                    <div>
                      <p className="bank_form_label">Bank Type</p>
                      <CustomTextField
                        disabled={isView}
                        name="bank_type"
                        placeholder={"Enter bank type"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bank_type}
                        error={
                          formik.touched.bank_type &&
                          Boolean(formik.errors.bank_type)
                        }
                        helperText={
                          formik.touched.bank_type && formik.errors.bank_type
                        }
                        FormHelperTextProps={{
                          style: { marginLeft: 0 },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div>
                <p className="bank_form_label">Address</p>
                <CustomTextArea
                  disabled={isView}
                  name="address"
                  fullWidth
                  multiline
                  maxRows={4}
                  placeholder={"Enter address"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  FormHelperTextProps={{
                    style: { marginLeft: 0 },
                  }}
                />
              </div>
              <div style={{ marginTop: "12px" }}>
                <p className="bank_form_label">About</p>
                <CustomTextArea
                  disabled={isView}
                  name="about"
                  fullWidth
                  multiline
                  maxRows={5}
                  placeholder={"Enter about"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about}
                  error={formik.touched.about && Boolean(formik.errors.about)}
                  helperText={formik.touched.about && formik.errors.about}
                  FormHelperTextProps={{
                    style: { marginLeft: 0 },
                  }}
                />
              </div>
            </form>
          </div>
          {!isView && (
            <div className="bank_form_action_button">
              <FormButton
                id="bankCreateSubmit"
                type="submit"
                form="bankform"
                variant="contained"
                // loading={loading}
                text={Bank_id ? "Update" : "Save"}
              />
              <FormButton
                id="bankCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => navigate(-1)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankForm;
