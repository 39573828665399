import JSZip from 'jszip';
export class Helpers {
    static Extentions = [
      'mp4',
        'avi',
        'mov',
        'mkv',
        'flv',
        'wmv',
        'webm',
        'mpeg',
        'mpg',
        'm4v',
        '3gp',
        'ogv',
        'ts',
        'vob',
        'm2ts',
        'f4v',
        'mxf'
    ];
  
    static getUrlExtensions(url) { 
      let filename = '';
  
      if (typeof url === 'string') {
        // Handle case when url is a string (e.g., a URL)
        filename = url;
      } else if (url instanceof File) {
        // Handle case when url is a File object
        filename = url.name;
      } else {
        // Unsupported type
        console.error("Unsupported URL type:", typeof url);
        return false;
      }
  
      // Split the filename to get the extension
      const parts = filename.split('.');
      if (parts.length < 2) {
        // If no extension found
        console.error("Invalid filename or URL:", filename);
        return false;
      }
      const extension = parts[parts.length - 1].toLowerCase();
      return this.Extentions.includes(extension);
    }
    static isVideoFile(fileType) {
        return this.Extentions.includes(fileType);
      }
    
      static isFileURL(file) {
        return typeof file === 'string' && (file.startsWith('http://') || file.startsWith('https://'));
      }
  
    static DownloadAssets(url) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }


    
    static async DownloadAssetsAsZip(attachments) {
      const zip = new JSZip();
  
      // Loop through each attachment and add it to the zip
      for (let attachment of attachments) {
        const response = await fetch(attachment.anfa_file_path);
        const blob = await response.blob();
        zip.file(attachment.anfa_file_name, blob);
      }
  
      // Generate the zip file and download
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'attachments.zip';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }

  }
  