import React, { useState } from "react";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import NoEncryptionOutlinedIcon from "@mui/icons-material/NoEncryptionOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./LoginForm.css";
import Logo from "../../../../assets/ProductLogo.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LocalStorageServices } from "../../../../services/localStorageServices.js";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../../api/apiServices/userApis.js";
import ResponseHandler from "../../../../services/ResponseHandler.js";
function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    const passwordField = document.getElementById('password');
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters")
    }),
    onSubmit: (values) => {
      setLoading(true)
      dispatch(userLogin(values)).then((response) => {
        LocalStorageServices.setLocalStorageData(response.data)
        navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/dashboard`);
      })

      .catch((error) => {
        ResponseHandler(error)
      })
      .finally(() => {
        setLoading(false)
      });
    },
  });

  return (

    <div className="loginForm_MainContainer">
      <div className="loginForm_SubContainer1">
        <div className="loginForm_ProductName_SubTitle">
          <img className="BankRealityLogo" src={Logo} alt="BankReality" />
          <h4 className="loginText">{t("Login Into Your Account")}</h4>
        </div>
      </div>
      <div className="loginFormContainer_Form">
        <form onSubmit={formik.handleSubmit}>
          <div className="loginFormField_Container">
            <p className="loginform_label">{t("Email Id")}</p>
            <TextField
              name="email"
              type="text"
              placeholder={t("Enter Your Email")}
              variant="outlined"
              fullWidth
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EmailOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
              sx={{
                "& input::placeholder": {
                  fontSize: "14px",
                  fontFamily: "inter",
                  color: "#555",
                },
              }}
              inputProps={{
                style: {
                  padding: 12,
                },
              }}
            />
          </div>
          <div className="loginFormField_Container">
            <p className="loginform_label">{t("Password")}</p>
            <TextField
              id="password"
              name="password"
              placeholder={t("Enter Your Password")}
              variant="outlined"
              type={'password'}
              fullWidth
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <NoEncryptionOutlinedIcon />
                      ) : (
                        <HttpsOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
              sx={{
                "& input::placeholder": {
                  fontSize: "14px",
                  fontFamily: "inter",
                  color: "#555",
                },
              }}
              inputProps={{
                style: {
                  padding: 12,
                },
              }}
            />
          </div>
          <p className="forget_password" onClick={() => {
            navigate('/admin_portal/forget_password')
          }}>{t("Forget Password ?")}</p>
          <button className="login_button" type="submit" disabled={isLoading}> <div>
            {isLoading ?
              <div>
                <CircularProgress size="1rem" color="inherit" />
              </div>
              : t("Login")}
          </div>
          </button>
        </form>
      </div>
      <div>
        <h4 className="loginForm_Copyright">
          {t("Bank Realty © 2024. All Rights Reserved")}
        </h4>
      </div>
    </div>
  );
}

export default LoginForm;
