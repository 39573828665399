import { createSlice } from "@reduxjs/toolkit";

export const propertyTypeSlice=createSlice({
    name:"property_type",
    initialState:{
        PropertyTypeTableLoading:false,
        WholePropertyTypes:[],
        PropertyTypes:[],
        filterTypeDetails:{
            type:"",
            status:"all",
        }
    },
    reducers:{
        setAllPropertyTypes:(state,action)=>{
            state.WholePropertyTypes=action.payload
            state.PropertyTypes=action.payload
        },
        updatePropertyTableLoading:(state,actions)=>{
            state.PropertyTypeTableLoading=actions.payload
        },
        setProperyFilterDatas:(state,actions)=>{
            const {name,value}=actions.payload
            state.filterTypeDetails[name]=value
        },
        filterPropertyTypeByData:(state)=>{
            let FilterType=[]
            if(state.filterTypeDetails.type.trim()){
                FilterType=state.WholePropertyTypes.filter((item)=>item.ptl_type.toLowerCase().includes(state.filterTypeDetails.type.toLowerCase()))
            }else{
                FilterType=state.WholePropertyTypes
            }

    // Filter by status
    if (state.filterTypeDetails.status !== "all") {
        const statusFilter = Number(state.filterTypeDetails.status); // Convert filter value to number
        console.log("Status Filter:", statusFilter); // Debugging
        FilterType = FilterType.filter((item) => item.ptl_status === statusFilter);
    }
    console.log("Filtered Results:", FilterType);
    
            state.PropertyTypes=FilterType
            console.log("Filter Status:", state.filterTypeDetails.status);

        },
        
        filterPropertyTypeByDataReset:(state)=>{
            state.PropertyTypes=state.WholePropertyTypes
            state.filterTypeDetails={type:"",status:"all",}
        },
        ResetPropertyTypeData:(state)=>{
            state.PropertyTypeTableLoading=false
            state.WholePropertyTypes=[]
            state.PropertyTypes=[]
            state.filterTypeDetails={
                type:"",
                status:"all"
            }
        }
    }
})

export const { setAllPropertyTypes,updatePropertyTableLoading,setProperyFilterDatas,filterPropertyTypeByData,filterPropertyTypeByDataReset,ResetPropertyTypeData}=propertyTypeSlice.actions

export default propertyTypeSlice.reducer