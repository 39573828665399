import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getInterestApplicationFilterBasedOnProperty, getInterestApplicationsBasedOnProperty, getInterestApplicationsBasedOnVehicles } from '../../../../../../api/apiServices/customerPortalApis'
import ResponseHandler from '../../../../../../services/ResponseHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination, styled } from '@mui/material';
import CustomTableWithOutPagination from '../../../../../../components/CustomTableWithOutPagination/CustomTableWithOutPagination'
import CustomChip from '../../../../../../components/customChip/CustomChip';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomInterestApplicationFilter from '../CustomInterestApplicationFilter/CustomInterestApplicationFilter';
import { setRefresh } from '../../../../../../store/usersStore';
import { Button, Box } from "@mui/material";
import SimpleTable from '../../../../../../components/simpleTable/SimpleTable';
function PropertyInterestsApplicationDetails() {
      const dispatch=useDispatch()
      const navigate=useNavigate()
      const { property_id } = useParams();
      const [PageNumber,SetPageNumber]=useState(1)
      const [pageCount,SetPageCount]=useState(0)
      const [PropertyInterestApplicationDetails, SetPropertyInterestApplicationDetails] = useState([]);
      const [isFiltered,setFiltered]=useState(false)
      const [values,setValues]=useState({
        "name": "",
        "email": "",
        "contact_number": "",
        "loan_required": "",
        "visit_required": ""
      })
      const hasAnyValue = (obj) => {
        return Object.values(obj).some(value => value !== "");
      };
      useEffect(()=>{
        if(hasAnyValue(values)){
          dispatch(getInterestApplicationFilterBasedOnProperty(property_id,PageNumber,10,values)).then((response)=>{
            const {interestApplication, count} = response
            SetPageCount(Math.ceil(count/10))
            SetPropertyInterestApplicationDetails(interestApplication);
          }).catch((error)=>{
            ResponseHandler(error)
          })
        }else{
          dispatch(getInterestApplicationsBasedOnProperty(property_id,PageNumber,10)).then((response)=>{
            const {interestApplication, count} = response
            SetPageCount(Math.ceil(count/10))
            SetPropertyInterestApplicationDetails(interestApplication);
          }).catch((error)=>{
            ResponseHandler(error)
          })
        }
        dispatch(setRefresh())
      },[PageNumber,isFiltered])
     
      const Columns = [
        {
          title: "#",
          dataIndex: "key",
          key: "key",
          
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email"
        },
        {
          title: "Contact Number",
          dataIndex: "contactNumber",
          key: "contactNumber"
        },
        {
          title: "Residential Address",
          dataIndex: "residentialAddress",
          key: "residentialAddress",
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
        },
        {
          title: "Loan Required",
          dataIndex: "loanRequired",
          key: "loanRequired",
        },
        {
          title: "Visit Required",
          dataIndex: "visitRequired",
          key: "visitRequired",
        },
        // {
        //   title: "KYC Doc",
        //   dataIndex: "Actions",
        //   key: "Actions",
        //   action: (row) => (
        //     <div>
        //       <CustomChip
        //         text="Kyc"
        //         bgColor="#F8893C"
        //         color="white"
        //       />
        //     </div>
        //   ),
        // },
      ];
      const Datas = () => {
        const result =
          PropertyInterestApplicationDetails &&
          PropertyInterestApplicationDetails.map((item, index) => {
            return {
              key: ((PageNumber*5)-(5-(index + 1))) ,
              id: item.cia_id,
              propertyID:item.cia_property_or_vehicle_id,
              name: item.cia_name,
              email: item.cia_email_address,
              contactNumber: item.cia_contact_number,
              residentialAddress: item.cia_residential_address,
              message:item.cia_message,
              loanRequired: item.cia_loan_required===0?'No':"Yes",
              visitRequired: item.cia_visit_required===0?'No':"Yes",
            };
          });
        return result;
      };
      
      
      const onchangeValues = async (event) => {
        const { value, name } = event.target;
        const currentLang = localStorage.getItem("currentLanguage") || "en";
        setValues({ ...values, [name]: value });
       
      };
      
      const onResetValues=()=>{
        setValues({
          "name": "",
        "email": "",
        "contact_number": "",
        "loan_required": "",
        "visit_required": ""
       })
       SetPageNumber(1)
       setFiltered(!isFiltered)
      }
    
      const onSearch=()=>{
        SetPageNumber(1)
        setFiltered(!isFiltered)
      }
     
      return (
        <div>
           <div  style={{display:'flex',justifyContent:'end',alignItems:'center',margin:'10px 0px'}}>
      <div
          className="back_button"
          onClick={() => {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/interest_applications?selected_intrerest_type=1`);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <CustomInterestApplicationFilter values={values} onChange={onchangeValues} onReset={onResetValues} onSubmit={onSearch}/>
        <SimpleTable columns={Columns} rows={Datas()}/>
       
    </div>
      )
    }

export default PropertyInterestsApplicationDetails