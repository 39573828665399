import React, { useEffect, useState } from "react";
import "./UserForm.css";
import { Button, TextField, Autocomplete, Paper } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setRolesDropDownData,
} from "../../../../../../store/usersStore";

import { GetAllRoles } from "../../../../../../api/apiServices/roleApis";
import {
  createNewUser,
  getUserById,
  updateUser,
} from "../../../../../../api/apiServices/userApis";
import FormButton from "../../../../../../components/formButton/FormButton";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";
import ResponseHandler from "../../../../../../services/ResponseHandler";
function UserForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const RolesDropdown = useSelector(
    (state) => state.userStore.RolesDropDownData
  );
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [loading, setLoading] = useState(false);
  const [spin, setSpin] = useState(true);
  useEffect(() => {
    dispatch(GetAllRoles()).then((Roleresponse) => {
      if (Roleresponse) {
        let rolesDetails = Roleresponse;
        if (Privilages?.url_role && Privilages?.url_role !== "SuperAdmin") {
          rolesDetails = rolesDetails.filter(
            (item) => item.url_role !== "SuperAdmin"
          );
        }
        dispatch(setRolesDropDownData(rolesDetails));
        if (id) {
          setSpin(true);
          dispatch(getUserById(id))
            .then((Userresponse) => {
              setFormikData(Roleresponse, Userresponse);
            })
            .catch((error) => {
              ResponseHandler(error);
            })
            .finally(() => {
              setSpin(false);
            });
        }
      }
    });
  }, []);

  const setFormikData = (roleResponse, userResponse) => {
    const data = userResponse.data[0];
    const rolefilter = roleResponse.find(
      (role) => role.url_role === data.url_role
    );
    formik.setValues({
      ...formik.values,
      name: data.ud_name,
      phone_no: data.ud_phone_no,
      email: data.up_email,
      role: rolefilter.url_id,
      address: data.ud_address,
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone_no: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      address: "",
      paramId: id || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone_no: Yup.string().required("Phone Number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().when("paramId", {
        is: (paramId) => !paramId,
        then: (schema) =>
          schema
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        otherwise: (schema) => schema,
      }),
      confirmPassword: Yup.string().when("paramId", {
        is: (paramId) => !paramId,
        then: (schema) =>
          schema
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        otherwise: (schema) => schema,
      }),
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (!id) {
        dispatch(createNewUser(values))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(updateUser(id, values))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <div>
      <p className="page_title">{id ? "Update User" : "Add User"}</p>
      <div className="user_form_top_action">
        <div
          className="user_form_back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon style={{ fontSize: 16 }} />
          <p>Back</p>
        </div>
      </div>
      <CustomSpin loading={id ? spin : false}>
        <div className="user_form_main_Container">
          <div className="user_formContainer">
            <p className="user_form_legend">
              {id ? "Update Details" : "Add Details"}
            </p>
            <form id="userform" onSubmit={formik.handleSubmit}>
              <div className="user_form_row">
                <div>
                  <p className="user_form_label">Name</p>
                  <TextField
                    name="name"
                    style={{ padding: 0, fontSize: 15, fontFamily: "Inter" }}
                    fullWidth
                    autoComplete="off"
                    className="user_form_input"
                    size="small"
                    placeholder={"Enter name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
                <div>
                  <label className="user_form_label">Phone Number</label>
                  <TextField
                    name="phone_no"
                    style={{ padding: 0, fontSize: 15, fontFamily: "Inter" }}
                    fullWidth
                    autoComplete="off"
                    className="user_form_input"
                    size="small"
                    placeholder={"Enter phone number"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_no}
                    error={
                      formik.touched.phone_no && Boolean(formik.errors.phone_no)
                    }
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
              </div>
              <div className="user_form_row">
                <div>
                  <label className="user_form_label">Email Address</label>
                  <TextField
                    name="email"
                    style={{ padding: 0, fontSize: 15, fontFamily: "Inter" }}
                    fullWidth
                    autoComplete="off"
                    className="user_form_input"
                    size="small"
                    placeholder={"Enter email address"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
                {!id ? (
                  <>
                    <div>
                      <label className="user_form_label">Password</label>
                      <TextField
                        name="password"
                        style={{
                          padding: 0,
                          fontSize: 15,
                          fontFamily: "Inter",
                        }}
                        fullWidth
                        autoComplete="off"
                        className="user_form_input"
                        size="small"
                        type="password"
                        placeholder={"Enter password"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        FormHelperTextProps={{
                          style: { marginLeft: 0 },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="filter_tool_field_width">
                    <label className="user_form_label">Roles</label>
                    <Autocomplete
                      name="role"
                      options={RolesDropdown}
                      componentsProps={{
                        paper: { sx: { wordBreak: "break-word" } },
                      }}
                      getOptionLabel={(option) => option.label}
                      fullWidth
                      autoComplete={false}
                      ListboxProps={{ style: { minHeight: 0 } }}
                      menuprops={{ style: { minHeight: 0 } }}
                      PaperComponent={(props) => (
                        <Paper
                          {...props}
                          style={{
                            ...props.style,
                            fontSize: 15,
                            fontFamily: "Inter",
                            margin: "0px",
                          }}
                        />
                      )}
                      className="user_form_autoinput"
                      size="small"
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "role",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        RolesDropdown.find(
                          (role) => role.value === formik.values.role
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Select Role"
                          error={
                            formik.touched.role && Boolean(formik.errors.role)
                          }
                          helperText={formik.touched.role && formik.errors.role}
                          FormHelperTextProps={{
                            style: { marginLeft: 0 },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              {!id && (
                <div className="user_form_row">
                  <div>
                    <label className="user_form_label">Confirm Password</label>
                    <TextField
                      style={{ padding: 0, fontSize: 15, fontFamily: "Inter" }}
                      name="confirmPassword"
                      fullWidth
                      autoComplete="off"
                      className="user_form_input"
                      size="small"
                      type="password"
                      placeholder={"Confirm password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                  <div className="user_field_width1" style={{ width: "100%" }}>
                    <label className="user_form_label">Roles</label>
                    <Autocomplete
                      name="role"
                      options={RolesDropdown}
                      getOptionLabel={(option) => option.label}
                      fullWidth
                      autoComplete={false}
                      ListboxProps={{ style: { minHeight: 0 } }}
                      menuprops={{ style: { minHeight: 0 } }}
                      PaperComponent={(props) => (
                        <Paper
                          {...props}
                          style={{
                            ...props.style,
                            fontSize: 15,
                            fontFamily: "Inter",
                            margin: "0px",
                          }}
                        />
                      )}
                      menuProps={{
                        style: { minHeight: 0, fontFamily: "Inter" },
                      }}
                      className="user_form_autoinput"
                      size="small"
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "role",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        RolesDropdown.find(
                          (role) => role.value === formik.values.role
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            padding: 0,
                            fontSize: 15,
                            fontFamily: "Inter",
                          }}
                          {...params}
                          placeholder="Select Role"
                          error={
                            formik.touched.role && Boolean(formik.errors.role)
                          }
                          helperText={formik.touched.role && formik.errors.role}
                          FormHelperTextProps={{
                            style: { marginLeft: 0, fontFamily: "Inter" },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="user_form_row">
                <div>
                  <label className="user_form_label">Address</label>
                  <TextField
                    name="address"
                    fullWidth
                    autoComplete="off"
                    className="user_form_multinput"
                    multiline
                    rows={6}
                    placeholder={"Enter address"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="user_form_action_button">
            <FormButton
              id="userCreateSubmit"
              type="submit"
              form="userform"
              variant="contained"
              loading={loading}
              text={id ? "Update" : "Save"}
            />
            <FormButton
              id="userCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </CustomSpin>
    </div>
  );
}

export default UserForm;
