import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import VehicleEnquirey from './vehicleEnquirey/VehicleEnquirey';
import PropertyEnquirey from './propertyEnquirey/PropertyEnquirey';

function Enquiries() {
    const navigate = useNavigate()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selected_enquirey_type = queryParams.get("selected_enquirey_type");
    const [selectedLookup, setselectedLookup] = useState(null)
    useEffect(() => {
        if (selected_enquirey_type) {
            setselectedLookup(selected_enquirey_type)
        } else {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/enquires`);
        }
    }, [selected_enquirey_type])
    const lookUpItems = [
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Property' },
    ];

    const handleLookUpClick = (id) => {
        navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/enquires?selected_enquirey_type=${id}`);
    };

    function UpdateTabData(id) {
        switch (id) {
            case '1':
                return <VehicleEnquirey/>;
            case '2':
                return <PropertyEnquirey/>;
            default:
                return <VehicleEnquirey/>;
        }
    }
    return (
        <div>
            <h3 className="page_title">Enquiries</h3>
            {/* <div className='lookUp_list_tags'>
                {lookUpItems.map(item => (
                    <p className='lookUp_tag' key={item.id}
                        onClick={() => handleLookUpClick(item.id)}
                        style={{
                            color: selectedLookup === `${item.id}` ? 'white' : 'black',
                            backgroundColor: selectedLookup === `${item.id}` ? '#0F62FE' : 'white'
                        }}>{item.label}</p>
                ))}
            </div> */}
            <PropertyEnquirey/>
        </div>
    )
}



export default Enquiries