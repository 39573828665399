import UiRoutes from "./routes/UiRoutes";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { LanguageProvider } from "./services/LanguageContext";
function App() {
  // const { i18n } = useTranslation();
  //   useEffect(()=>{
  //      const lgCode= localStorage.getItem('lgCode')
  //      if(!lgCode){
  //       localStorage.setItem('lgCode','en');
  //       i18n.changeLanguage('en')
  //      }else{
  //       i18n.changeLanguage( lgCode)
  //      }
  //   },[])

  return (
    <>
      {/* <LanguageProvider> */}
        <ToastContainer />
        <UiRoutes />
      {/* </LanguageProvider> */}
      {/*// google translate logic <div id="google_translate_element"></div> */}
    </>
  );
}

export default App;
