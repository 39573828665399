import { ApiConfig } from "../apiConfig";
export const getAllStatus = () => {
  return async () => {
    try {
      const response = await ApiConfig.get("status");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
};

export const getAllpossessions = () => {
  return ApiConfig.get("property/get_all_possessions")
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getAllEmdTypes = () => {
    return ApiConfig.get("property/get_all_emd_types")
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };
