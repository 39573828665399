import { createSlice } from "@reduxjs/toolkit";

export const PropertiesStoreSlice = createSlice({
  name: "properties",
  initialState: {
    selectedTab: 0,
    wholeProperty: [],
    defaultChecked: ["key", "property_name", "property_type", "sale_price", "market_value", "seller", "sale_status", "status"],
    property: [],
    PropertyTaskTableLoading: false,
    WholePropertyTasks: [],
    PropertyTasks: [],
    FilterDropDownList: {
      type: [],
      bank: [],
      status: []
    },
    propertyFilterData: {
      type: null,
      status: null,
      bank: null,
      start_date: null,
      end_date: null,
      sale_status: null,
    },
    PropertyTaskFilterData: {
      taskName: "",
      assign_to: null,
      start_date: null,
      status: null,
      end_date: null,
      priority: null
    },
    PropertyTaskFilterDropDownList: {
      assign_to: [],
      status: [],
      priority: []
    },
    totalCounts: 0,
  },
  reducers: {
    updateDefaultChecked: (state, action) => {
      const { isChecked, name } = action.payload;
      if (isChecked) {
        state.defaultChecked = [...state.defaultChecked, name];
      } else {
        let filterUncheckedData = state.defaultChecked;
        filterUncheckedData = filterUncheckedData.filter(
          (item) => item !== name
        );
        state.defaultChecked = filterUncheckedData;
      }
    },
    updateSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setAllProperty: (state, actions) => {
      state.wholeProperty = actions.payload;
      state.property = actions.payload;
      state.totalCounts = actions.payload.totalCounts;
    },
    SetAllPropertyTasks: (state, actions) => {
      state.PropertyTasks = actions.payload;
      state.WholePropertyTasks = actions.payload;
    },

    updateFilterDropdownData: (state) => {
      const stringifyPropertyData = JSON.stringify(state.wholeProperty)
      const parsePropertyData = JSON.parse(stringifyPropertyData)
      const UniqueType = [...new Set(parsePropertyData.map(item => item.pd_property_type_name))].map(type => ({ label: type, value: type }))
      const UniqueBank = [...new Set(parsePropertyData.map(item => item.pd_seller))].map(type => ({ label: type, value: type }))
      const UniqueStatus = [...new Set(parsePropertyData.map(item => item.pd_status_name))].map(type => ({ label: type, value: type }))
      state.FilterDropDownList = { type: UniqueType, bank: UniqueBank, status: UniqueStatus }
      console.log(UniqueType, "UniqueType");

    },
    updatePropertyTaskFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.WholePropertyTasks)
      const parseData = JSON.parse(stringifyData)
      const UniqueAssignTo = [...new Set(parseData.filter(item => item.pt_assignto !== null).map(item => item.pt_assignto))].map(type => ({ label: type, value: type }))
      const UniqueStatus = [...new Set(parseData.filter(item => item.sl_status !== null).map(item => item.sl_status))].map(type => ({ label: type, value: type }))
      const UniquePriority = [...new Set(parseData.filter(item => item.pl_priority !== null).map(item => item.pl_priority))].map(type => ({ label: type, value: type }))
      state.PropertyTaskFilterDropDownList = { assign_to: UniqueAssignTo, status: UniqueStatus, priority: UniquePriority }
    },
    setPropertyFilterDatas: (state, actions) => {
      state.propertyFilterData = actions.payload
    },
    setPropertyTaskFilterDatas: (state, actions) => {
      state.PropertyTaskFilterData = actions.payload
    },
    filterPropertyData: (state, actions) => {
      const WholeProperty = JSON.stringify(state.wholeProperty);
      const FilterData = JSON.stringify(state.propertyFilterData);
      const ParseWholeProperty = JSON.parse(WholeProperty)
      const ParseFilterData = JSON.parse(FilterData)
      let FilterdData = []
      if (ParseFilterData.type) {
        FilterdData = ParseWholeProperty.filter((item) => item.pd_property_type_name === ParseFilterData.type)
      } else {
        FilterdData = ParseWholeProperty
      }

      if (ParseFilterData.bank) {
        FilterdData = FilterdData.filter((item) => item.pd_seller == ParseFilterData.bank)
      }

      if (ParseFilterData.status) {
        FilterdData = FilterdData.filter((item) => item.pd_status_name === ParseFilterData.status)
      }
      
  // Ensure start_date and end_date are formatted correctly
  if (ParseFilterData.start_date && ParseFilterData.end_date) {
    const startDateParts = ParseFilterData.start_date.split('/');
    const endDateParts = ParseFilterData.end_date.split('/');
  
    const formattedStartDate = `${startDateParts[2]}-${startDateParts[1].padStart(2, '0')}-${startDateParts[0].padStart(2, '0')}`;
    const formattedEndDate = `${endDateParts[2]}-${endDateParts[1].padStart(2, '0')}-${endDateParts[0].padStart(2, '0')}`;
  
    FilterdData = FilterdData.filter((item) =>
      new Date(item.pd_application_start_date) >= new Date(formattedStartDate) &&
      new Date(item.pd_application_end_date) <= new Date(formattedEndDate)
    );
  }
  
      // if (ParseFilterData.start_date && ParseFilterData.end_date) {
      //   FilterdData = FilterdData.filter((item) => new Date(item.pd_created_date) >= new Date(ParseFilterData.start_date) && new Date(item.pa_reported_date) <= new Date(ParseFilterData.end_date))
      // }
      if (ParseFilterData.sale_status !== null && ParseFilterData.sale_status !== 'Incompleted') {
        FilterdData = FilterdData.filter((item) => item.ps_sale_status === ParseFilterData.sale_status)
      }
      if (ParseFilterData.sale_status === 'Incompleted') {
        FilterdData = FilterdData.filter((item) => item.ps_sale_status == null)
      }
      state.property = FilterdData
    },
    searchFilterPropertyTaskData: (state, action) => {
      const WholePropertyTask = JSON.stringify(state.WholePropertyTasks);
      const FilterTaskData = JSON.stringify(state.PropertyTaskFilterData);
      const ParseWholeVehicleTasks = JSON.parse(WholePropertyTask)
      const ParseFilterVehicleTaskData = JSON.parse(FilterTaskData)
      let filterData = []
      if (ParseFilterVehicleTaskData.taskName.trim()) {
        filterData = ParseWholeVehicleTasks.filter((item) => {
          const ptTaskName = item.pt_taskname || '';
          return ptTaskName.toLowerCase().includes(ParseFilterVehicleTaskData.taskName.trim().toLowerCase());
        });
      } else {
        filterData = ParseWholeVehicleTasks
      }
      if (ParseFilterVehicleTaskData.assign_to) {
        filterData = filterData.filter((item) => item.up_email === ParseFilterVehicleTaskData.assign_to);
      }
      if (ParseFilterVehicleTaskData.start_date && ParseFilterVehicleTaskData.end_date) {
        filterData = filterData.filter((item) => new Date(item.pt_startdate) >= new Date(ParseFilterVehicleTaskData.start_date) && new Date(item.pt_enddate) <= new Date(ParseFilterVehicleTaskData.end_date))
      }
      if (ParseFilterVehicleTaskData.priority) {
        filterData = filterData.filter((item) => item.pl_priority === ParseFilterVehicleTaskData.priority)
      }
      if (ParseFilterVehicleTaskData.status) {
        filterData = filterData.filter((item) => item.sl_status === ParseFilterVehicleTaskData.status)
      }
      state.PropertyTasks = filterData
    },
    setResetFilter: (state, action) => {
      const WholeProperty = JSON.stringify(state.wholeProperty);
      const ParseWholeProperty = JSON.parse(WholeProperty)
      state.propertyFilterData = {
        type: null,
        status: null,
        bank: null,
        start_date: null,
        end_date: null,
        sale_status: null
      }
      state.property = ParseWholeProperty
    },
    setPropertyTaskResetFilter: (state, action) => {
      const WholeProperty_Tasks = JSON.stringify(state.WholePropertyTasks);
      const ParseWholeProperty_Task = JSON.parse(WholeProperty_Tasks);
      state.PropertyTaskFilterData = {
        taskName: "",
        assign_to: null,
        start_date: null,
        status: null,
        end_date: null,
        priority: null
      }
      state.PropertyTasks = ParseWholeProperty_Task
    }
  },
});

export const {
  updateDefaultChecked,
  updateSelectedTab,
  setAllProperty,
  PropertyTaskTableLoading,
  SetAllPropertyTasks,
  updateFilterDropdownData,
  setPropertyFilterDatas,
  setResetFilter,
  filterPropertyData,
  setPropertyTaskFilterDatas,
  updatePropertyTaskFilterDropdown,
  searchFilterPropertyTaskData,
  setPropertyTaskResetFilter
} = PropertiesStoreSlice.actions;
export default PropertiesStoreSlice.reducer;
