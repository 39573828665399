import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./NotificationPage.css";
import { updateNotificationIsRead } from "../../../../api/apiServices/notificationApi";
import { DateTimeConvertionService } from "../../../../services/DateTimeConvertionService";
import No_Notification from "../../../../assets/notification.jpg"
function NotificationPage() {
  const notifications = useSelector((state) => state.mainStore.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateNotificationIsRead());
  }, []);
  return (
    <div>
      <div>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <div key={notification.n_id} className="notification_main_container">
              <div className="notification_email_dateTime">
                <p className="notification_email">{notification.up_email}</p>
                <p className="notification_message">{notification.n_message}</p>
              </div>
              <div>
                <p className="notification_dateTime">
                  {DateTimeConvertionService.DateTimeConverter(
                    notification.n_time_stamp
                  )}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className='main_not_notification_container'>

            <div>
              <div className='no_Notification_centralize_content'>
                <img src={No_Notification} className='no_notification_image' />
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}

export default NotificationPage;
