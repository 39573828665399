import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getSuperAdminDashboardDetails } from '../../../../../api/apiServices/dashboardApis'
import './superAdminDashboard.css'
import dummyIcon from '../../../../../assets/dummy_icon_for_dashboard.png'
import CustomDashboardSmallCard from '../../../../../components/customDashboardSmallCard/CustomDashboardSmallCard'
import { LinearProgress } from '@mui/material'
import CustomLineChart from '../../../../../components/customLineChart/CustomLineChart'
import { propertyPlotTags, vehiclePlotTags } from './superAdminDashboardHelper'
import CustomYearsDropDown from '../../../../../components/customYearsDropDown/CustomYearsDropDown'
function SuperAdminDashboard() {
    const [dashboardDetails, setDashboardDetails] = useState(null)
    const [selectedVehicleTag, setSelectedVehicleTag] = useState(null)
    const [selectedVehicleYear, setselectedVehicleYear] = useState(null)
    const [selectedPropertyYear, setselectedPropertyYear] = useState(null)
    const [selectedPropertyTag, setSelectedPropertyTag] = useState(null)
    const dispach = useDispatch()
    useEffect(() => {
        dispach(getSuperAdminDashboardDetails()).then((response) => {
            setDashboardDetails(response)
            setSelectedVehicleTag("1")
            setSelectedPropertyTag("1")
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    useEffect(() => {
        propertyPlotSelected(selectedPropertyTag)
        setselectedPropertyYear(propertyPlotSelectedYear(selectedPropertyTag))
    }, [selectedVehicleTag, selectedPropertyTag])
    const LinearStyles = {
        height: "12px",
        borderRadius: "4px",
        color: "rgba(91, 147, 255, 1)",
        mt: 0.5,
    };
   
    const propertyPlotSelected = (selectedPropertyTag) => {
        if (!dashboardDetails) return [];
        switch (selectedPropertyTag) {
            case "1":
                return dashboardDetails?.property_for_plot.map((item) => item.year)
            case "2":
                return dashboardDetails?.property_ready_sale_for_plot.map((item) => item.year)
            case "3":
                return dashboardDetails?.property_sold_out_for_plot.map((item) => item.year)
            case "4":
                return dashboardDetails?.property_interest_enquirey_for_plot.map((item) => item.year)
            case "5":
                return dashboardDetails?.property_loan_enquirey_for_plot.map((item) => item.year)
            default:
                return [];
        }
    }
   
    const propertyPlotSelectedYear = (selectedPropertyTag) => {
        if (!dashboardDetails) return '';
        switch (selectedPropertyTag) {
            case "1":
                return dashboardDetails?.property_for_plot.map((item) => item.year)[0]?.toString() || '';
            case "2":
                return dashboardDetails?.property_ready_sale_for_plot.map((item) => item.year)[0]?.toString() || '';
            case "3":
                return dashboardDetails?.property_sold_out_for_plot.map((item) => item.year)[0]?.toString() || '';
            case "4":
                return dashboardDetails?.property_interest_enquirey_for_plot.map((item) => item.year)[0]?.toString() || '';
            case "5":
                return dashboardDetails?.property_loan_enquirey_for_plot.map((item) => item.year)[0]?.toString() || '';
            default:
                return '';
        }
    }
    const transformData = (data, year) => {
        const monthsOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        if (!Array.isArray(data) || data.length === 0) return [];

        const filteredData = data.find(item => item.year == year);
        if (!filteredData) return [];
        return monthsOrder.map(month => filteredData[month] || 0);
    };
    const vehiclePlotData = (selectedVehicleTag, year) => {
        if (!dashboardDetails) return [];
        switch (selectedVehicleTag) {
            case "1":
                return transformData(dashboardDetails?.vehicle_for_plot, year)
            case "2":
                return transformData(dashboardDetails?.vehicle_ready_sale_for_plot, year)
            case "3":
                return transformData(dashboardDetails?.vehicle_sold_out_for_plot, year)
            case "4":
                return transformData(dashboardDetails?.vehicle_interest_enquirey_for_plot, year)
            case "5":
                return transformData(dashboardDetails?.vehicle_loan_enquirey_for_plot, year)
            default:
                return [];
        }
    }
    const propertyPlotData = (selectedPropertyTag, year) => {
        if (!dashboardDetails) return '';
        switch (selectedPropertyTag) {
            case "1":
                return transformData(dashboardDetails?.property_for_plot, year);
            case "2":
                return transformData(dashboardDetails?.property_ready_sale_for_plot, year);
            case "3":
                return transformData(dashboardDetails?.property_sold_out_for_plot, year);
            case "4":
                return transformData(dashboardDetails?.property_interest_enquirey_for_plot, year);
            case "5":
                return transformData(dashboardDetails?.property_loan_enquirey_for_plot, year);
            default:
                return '';
        }
    }
    const setVehicleYear = (year) => {
        setselectedVehicleYear(year)
    }
    const setPropertyYear = (year) => {
        setselectedPropertyYear(year)
    }
    return (
        <div className='dashboard_main_container'>
            <div className='dashboard_sub_listing_container_1'>
                <CustomDashboardSmallCard image={dummyIcon} name={'Users'} count={dashboardDetails?.users?.total_users} />
                <CustomDashboardSmallCard image={dummyIcon} name={'Agents'} count={dashboardDetails?.agents?.total_agents} />
                <CustomDashboardSmallCard image={dummyIcon} name={'Portal Users'} count={dashboardDetails?.customer_portal_users?.total_customer_portal_users} />
                <CustomDashboardSmallCard image={dummyIcon} name={'Portal Agents'} count={dashboardDetails?.customer_portal_agents?.total_customer_portal_agents} />
                <CustomDashboardSmallCard image={dummyIcon} name={'Properties'} count={dashboardDetails?.property?.total_properties} />
            </div>
            <div className='dashboard_sub_listing_container_2'>
        
                <div className='dashboard_sub_item_container_2_1'>
                    <p className='dashboard_sub_item_title_container_2_1'>Property Sale Status</p>
                    <p className='dashboard_sub_item_sub_title_container_2_1'>Ready for sale <span className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_ready_for_sale?.total_properties_ready_for_sale}/{dashboardDetails?.property?.total_properties})</span></p>
                    <LinearProgress variant="determinate" value={Math.round((dashboardDetails?.property_ready_for_sale?.total_properties_ready_for_sale / dashboardDetails?.property?.total_properties) * 100)} sx={{ ...LinearStyles }} />
                    <p className='dashboard_sub_item_sub_title_container_2_1'>Sold out <span className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_sold_out?.total_properties_sold_out}/{dashboardDetails?.property?.total_properties})</span></p>
                    <LinearProgress variant="determinate" value={Math.round((dashboardDetails?.property_sold_out?.total_properties_sold_out / dashboardDetails?.property?.total_properties) * 100)} sx={{ ...LinearStyles }} />
                    <p className='dashboard_sub_item_sub_title_container_2_1'>Not for sale <span className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_not_for_sale?.total_properties_not_for_sale}/{dashboardDetails?.property?.total_properties})</span></p>
                    <LinearProgress variant="determinate" value={Math.round((dashboardDetails?.property_not_for_sale?.total_properties_not_for_sale / dashboardDetails?.property?.total_properties) * 100)} sx={{ ...LinearStyles }} />
                    <p className='dashboard_sub_item_sub_title_container_2_1'>Incompleted for sale <span className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_incompleted_for_sale?.total_properties_incompleted_for_sale}/{dashboardDetails?.property?.total_properties})</span></p>
                    <LinearProgress variant="determinate" value={Math.round((dashboardDetails?.property_incompleted_for_sale?.total_properties_incompleted_for_sale / dashboardDetails?.property?.total_properties) * 100)} sx={{ ...LinearStyles }} />
                  
                </div>
                <div className='dashboard_sub_item_container_2_1'>
                <p className='dashboard_sub_item_title_container_2_1'>Property Interests & Loan Status</p>
                    <p className='dashboard_sub_item_sub_title_container_2_1'>Interest enquire <span  className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_interest_enquire?.total_properties_interest_enquire})</span></p>
                 <LinearProgress variant="determinate" value={dashboardDetails?.property_interest_enquire?.total_properties_interest_enquire} sx={{ ...LinearStyles }} />
                 <p className='dashboard_sub_item_sub_title_container_2_1'>Loan enquire  <span  className='dashboard_sub_item_sub_value_container_2_1'>({dashboardDetails?.property_loan_enquire?.total_properties_loan_enquire})</span></p>
                 <LinearProgress variant="determinate" value={dashboardDetails?.property_loan_enquire?.total_properties_loan_enquire} sx={{ ...LinearStyles }} />
                </div>
            </div>
            <div className='dashboard_sub_listing_container_3'>
                
                <div className='dashboard_sub_item_container_3_1 '>
                    <div className='dashboard_sub_item_container_3_2'>
                        <p className='dashboard_sub_item_container_overview_title'>Property Overview</p>
                        <div>
                            <CustomYearsDropDown selected={selectedPropertyYear} list={propertyPlotSelected(selectedPropertyTag)} setSelectedYear={setPropertyYear} />
                        </div>
                    </div>
                    <div className='dashboard_sub_item_container_3_3'>
                        {Object.entries(propertyPlotTags).map(([key, value]) => (
                            <p key={key} className='dashboard_sub_item_tag_3_3' style={{
                                color: selectedPropertyTag == key ? 'white' : 'black',
                                backgroundColor: selectedPropertyTag == key ? '#1976d2' : 'white'
                            }} onClick={() => {
                                setSelectedPropertyTag(key)
                            }}>{value}</p>
                        ))}
                    </div>
                    <  div className=' graph-container'>
                        <CustomLineChart plotData={propertyPlotData(selectedPropertyTag, selectedPropertyYear)} />
                        {!selectedPropertyYear && (
                            <div className='no-data-overlay'>No Data</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuperAdminDashboard