import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HomePageSlider from "./subComponents/homePageSlider/HomePageSlider";
import HomePageHeader from "./subComponents/homePageHeader/HomePageHeader";
import './HomePage.css'
import CustomCard from "../../components/customCard/CustomCard";
import { LocalStorageServices } from "../../services/localStorageServices";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../store/homePageStore";
import { getUserRoleWithPrivilage } from "../../api/apiServices/userApis";

import { socketServices } from "../../services/socketServices";
function HomePage() {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  // const SOCKET_SERVER_URL = 'http://localhost:8000';
  const socketManager = new socketServices(process.env.REACT_APP_BASE_SOCKET_URL,dispatch);
  useEffect(()=>{
    let data = LocalStorageServices.getLocalStorageData()
    if(data){
      dispatch(setUserDetails(JSON.parse(data)))
      if(JSON.parse(data).up_role_id){
        socketManager.connect(JSON.parse(data).up_token);
        // socket.emit('token',JSON.parse(data).up_token);
        // socket.on('TaskAssign', (message) => {
        //   InfoToast(message)
        // });
        dispatch(getUserRoleWithPrivilage(JSON.parse(data).up_role_id))
      }else{
        navigate('/login')
      }
    }else{
      navigate('/login')
    }
  },[])
  return (
    <div className="homePage_main_container">
      <div>
        <HomePageSlider />
      </div>
      <div className="homePage_header_outlet_container">
        <HomePageHeader />
        <div className="homePage_main_content_container">
          <div className="homePage_main_content_container_outlet">
            <CustomCard>
            <div>
        </div>
              <Outlet />
            </CustomCard>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default HomePage;
