import React, { useEffect, useState } from "react";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./AttachmentForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import { FiUpload } from "react-icons/fi";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import Pdf_Logo from "../../../../../../assets/Pdf_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helpers } from "../../../../../../services/Helpers";
import * as Yup from "yup";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import {
  getPropertyAttachment,
  updatePropertyAttachment,
} from "../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { RiFileEditFill } from "react-icons/ri";
function AttachmentForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { property_id } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]); // Store multiple files
  const [loading, setLoading] = useState(false);
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Isview, SetIsview] = useState(true);

  useEffect(() => {
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyAttachment(property_id))
        .then((response) => {
          const data = response.data.data;
          setAttachments(data ? data : []);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setSelectedImages((prevImages) => [...prevImages, ...files]); // Add new files to the existing state
  };

  const handleRemoveImage = (fileToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((file) => file !== fileToRemove)
    );
  };

  const handleRemoveAttachment = (attachmentToRemove) => {
    setAttachments((prev) =>
      prev.map((att) =>
        att.pfa_id === attachmentToRemove.pfa_id
          ? { ...att, isDeleted: true } // Mark as deleted instead of removing it
          : att
      )
    );
  };

  const onSubmit = () => {
    const data = new FormData();
 
    // Append each selected image to form data (newly added images only)
    selectedImages.forEach((file) => {
      data.append("property_file_attachment", file);
    });
 
    // Filter out the deleted attachments from the current state
    const deletedAttachments = attachments
      .filter((attachment) => attachment.isDeleted)
      .map((attachment) => attachment.pfa_id); // Send only the IDs of deleted attachments
 
    // // Filter out attachments that are not deleted and prepare the data
    // const attachmentsToSend = attachments
    //   .filter((attachment) => !attachment.isDeleted)
    //   .map((attachment) => {
    //     return {
    //       pfa_id: attachment.pfa_id,
    //       pfa_file_path: attachment.pfa_file_path,
    //       pfa_file_name: attachment.pfa_file_name,
    //       pfa_file_type: attachment.pfa_file_type,
    //     };
    //   });
 
    // // Append the remaining (not deleted) attachments to the form data
    // attachmentsToSend.forEach((attachment) => {
    //   data.append("existing_attachments", JSON.stringify(attachment));
    // });
 
    // Append deleted attachment IDs
    if (deletedAttachments.length > 0) {
      data.append("deleted_attachments", deletedAttachments);
    }
 

    setLoading(true);
    dispatch(updatePropertyAttachment(property_id, data))
      .then((response) => {
        ResponseHandler(response);
        if (iSViewAndEdit) {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${2}&view_edit=${true}`
          );
        } else {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${2}`
          );
        }
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="Property_BankDetails_formContainer" style={{ marginTop: "30px" }}>
        <p className="Property_BankDetails_form_legend">Add Property Attachments</p>
        {Privilages &&
          Privilages?.Property &&
          Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
            <div
              className="Property_detailsfrom_edit_details"
              onClick={() => SetIsview(false)}
            >
              <RiFileEditFill />
              <p>Edit</p>
            </div>
          )
          : null}
        <form id="Property_BankDetailsform" onSubmit={onSubmit}>
          <div className="Property_BankDetailsform_fields">
            <div className="fileuploader_container_Auction_Attachment">
              <div
                className="file-upload-containers_Attachment"
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "55px",
                }}
              >
                <p className="Attachment_title">Upload Property Attachment Files</p>
                <div>
                  <input
                    disabled={Isview}
                    type="file"
                    accept=".png, .jpg, .jpeg, .pdf, .mp4"
                    name="myImage"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                    multiple
                  />
                  <label
                    htmlFor="file-input"
                    style={{ cursor: "pointer", display: "inline-block" }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0 auto",
                        position: "relative",
                      }}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ fontSize: "40px", color: "#1da1f2" }}
                      />
                    </div>
                  </label>
                  <p style={{ color: "#9093a1", fontSize: "14px" }}>
                    JPG, PNG, PDF, and MP4 files up to 50 Mb
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "12px",
                  }}
                >
                  <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                    <Button
                      disabled={Isview}
                      variant="outlined"
                      component="span"
                      style={{
                        color: "#9093a1",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        padding: "3px 10px",
                        marginLeft: "-20px",
                      }}
                    >
                      Browse
                    </Button>
                  </label>
                </div>
              </div>


              {/* Display existing attachments */}
              <div className="Attachment_lists">
                {attachments
                  .filter((attachment) => !attachment.isDeleted) // Only show attachments that are not marked as deleted
                  .map((attachment, index) => (
                    <div className="img-div-attachment" key={attachment.pfa_id}>
                      {Helpers.getUrlExtensions(attachment.pfa_file_path) ? (
                        <video
                          controls
                          src={attachment.pfa_file_path}
                          className="video_attachment"
                          alt="Existing Video"
                        />
                      ) : (
                        <img
                          alt="Existing"
                          src={attachment.pfa_file_path}
                          className="video_attachment"
                        />
                      )}
                      <div
                        className="attachment_close_icon_Propertytype"
                        onClick={() => handleRemoveAttachment(attachment)}
                      >
                        <CancelIcon
                          className="attachemnt_cancelIcon"
                          style={{ display: Isview ? "none" : "block" }}
                        />
                      </div>
                    </div>
                  ))}

                {/* Display newly selected images/videos */}
                {selectedImages.map((file, index) => (
                  <div className="img-div-attachment" key={index}>
                    {Helpers.getUrlExtensions(file.name) ? (
                      <video
                        controls
                        src={URL.createObjectURL(file)}
                        className="video_attachment"
                        alt="Selected Video"
                      />
                    ) : (
                      <img
                        alt="Selected"
                        src={URL.createObjectURL(file)}
                        className="image_attachment"
                      />
                    )}
                    <div
                      className="attachment_close_icon_Propertytype"
                      onClick={() => handleRemoveImage(file)}
                    >
                      <CancelIcon className="attachemnt_cancelIcon" />
                    </div>
                  </div>
                ))}
              </div>


            </div>
          </div>
        </form>
        {!Isview && (
          <div className="Attachmentform_action">
            <FormButton
              id="AttachmentCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                );
              }}
            />
            <FormButton
              id="AttachmentCreateSubmit"
              type="submit"
              form="Attachmentform"
              variant="contained"
              onClick={onSubmit}
              loading={loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AttachmentForm;
