import React, { useState, useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Button, Box } from "@mui/material";
import { IconButton, Pagination, Table } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./SimpleTable3.css";
import { setRefresh } from "../../store/usersStore";

function SimpleTable3({ columns, rows,tableId }) {
  const [currentPage, setCurrentPage] = useState(() => {
    // Retrieve the saved page number for this specific table from localStorage or default to 1
    return parseInt(localStorage.getItem(`currentPage_${tableId}`)) || 1;
  });
  const dispatch=useDispatch()
  useEffect(()=>{
   
      dispatch(setRefresh())

    
  },[currentPage])
  
  const [rowData, setRowData] = useState([]);
  const { t } = useTranslation();
  // responsive table start
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth < 999) {
      setTableResp({ margin: "10px auto" });
    } else {
      setTableResp({
        margin: "10px auto",
        maxWidth: IsCollapsed ? "calc(100vw - 120px)" : "calc(100vw - 320px)",
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);

  // responsive table end

  useEffect(() => {
    if (rows) {
    // Ensure the current page is within bounds for the new data set
    const totalPages = Math.ceil(rows.length / 8);
    if (currentPage > totalPages) {
      setCurrentPage(1);
      localStorage.setItem(`currentPage_${tableId}`, 1);
    }
    slicedTableData(currentPage);
  }
}, [rows, currentPage]);


  function slicedTableData(currentPageNumber) {
    const firstPageIndex = (currentPageNumber - 1) * 8;
    const lastPageIndex = firstPageIndex + 8;
    const slicedData = rows.slice(firstPageIndex, lastPageIndex);
    setRowData([...slicedData]);
  }
  const handleChange = (_, value) => {
    slicedTableData(value);
    setCurrentPage(value);
    // Save the current page for this specific table to localStorage
    localStorage.setItem(`currentPage_${tableId}`, value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E9F2FF",
      color: "black",
      borderBottom: "none",
      fontWeight: "600",
      fontFamily: "Inter",
      fontSize: 15,
      padding: "15px 12px",
    },
    [`&.${tableCellClasses.body}`]: {
      maxWidth: "500px",
      overflowWrap: "break-word",
      fontSize: 15,
      borderBottom: "none",
      padding: "12px",
      overflow: "auto",
      whiteSpace: "normal",
      fontFamily: "Inter",
      wordWrap: "break-word",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      "& > *:first-of-type": {
        backgroundColor: "white",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#E9F2FF",
      "& > *:first-of-type": {
        backgroundColor: "#E9F2FF",
      },
    },
  }));

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      backgroundColor: "#EDEFF6",
      color: "#4A60A1",
      "&.Mui-selected": {
        backgroundColor: "#003087",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "#003087",
        color: "white",
      },
    },
  }));
  const renderCustomPagination = () => {
    const totalPages = Math.ceil(rows.length / 8);
    const pageRange = 3;
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const endPage = Math.min(totalPages, startPage + pageRange - 1);
    if (endPage - startPage + 1 < pageRange) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }

    const pages = [];

    if (currentPage > 1) {
      pages.push(
        <Button
          key="first"
          variant="outlined"
          onClick={() => handleChange(null, 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<<"}
        </Button>
      );

      pages.push(
        <Button
          key="prev"
          variant="outlined"
          onClick={() => handleChange(null, currentPage - 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<"}
        </Button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          onClick={() => handleChange(null, i)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
            color: currentPage === i ? "white" : "#4A60A1",
            "&:hover": {
              backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
              color: currentPage === i ? "white" : "#4A60A1",
            },
          }}
        >
          {i}
        </Button>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <Button
          key="next"
          variant="outlined"
          onClick={() => handleChange(null, currentPage + 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {">"}
        </Button>
      );

      pages.push(
        <Button
        key="last"
        variant="outlined"
        onClick={() => handleChange(null, totalPages)}
        sx={{
          minWidth: "30px",
            padding: "3px",
          margin: '0 5px',
          backgroundColor: '#EDEFF6',
          color: '#4A60A1',
          '&:hover': {
            backgroundColor: '#EDEFF6',
            color: '#4A60A1',
          },
        }}
      >
        {'>>'}
      </Button>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        {pages}
      </Box>
    );
  };


  return (
    <div style={TableResp}>
      <div>
        <TableContainer className="advance_table_mainContainer">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => {
                    return (
                      <>
                        <StyledTableCell
                          key={column.key}
                          align={
                            column.dataIndex === "Actions" ? "center" : "left"
                          }
                          style={{
                            width: column.dataIndex === "key" ? "20px" : "",
                          }}
                        
                        >
                          {column.title}
                        </StyledTableCell>
                      </>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData &&
                rowData.map((row, index) => (
                  <StyledTableRow key={row.key}>
                    {columns.map((column) => (
                      <>
                        <StyledTableCell
                          key={column.key}
                          align={
                            column.dataIndex === "Actions" ? "center" : "left"
                          }
                          style={{
                            width: column.dataIndex === "key" ? "20px" : "",
                            backgroundColor:
                              column.dataIndex === "Actions"
                                ? index % 2 === 0
                                  ? "#ffffff"
                                  : "#E9F2FF"
                                : "",
                          }}
                          id="sticky-actions1"
                         
                        >
                          {column.action ? (
                            column.action(row)
                          ) : (
                            <div
                              className={
                                column.key !== "key" ? "textcontainer" : ""
                              }
                            >
                              <p>
                                {row[column.dataIndex]
                                  ? column.dataIndex === "taskDescription"
                                    ? row[column.dataIndex].length >= 20
                                      ? row[column.dataIndex].slice(0, 20) +
                                        "..."
                                      : row[column.dataIndex]
                                    : row[column.dataIndex].length >= 20
                                    ? row[column.dataIndex].slice(0, 20) + "..."
                                    : row[column.dataIndex]
                                  : " - "}
                              </p>
                            </div>
                          )}
                        </StyledTableCell>
                      </>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          {rows && rows.length === 0 && (
            <div className="noData_Container">
              <p className="noData_text">{"No Data"}</p>
            </div>
          )}
        </TableContainer>
      </div>
      <div>
        {rowData && rowData.length > 0 && (
          <div className="table_pagination_container">
          <button
            className="table_pagination_button"
            style={{ color: currentPage === 1 ? "#005EB8" : "" }}
            onClick={() => {
              slicedTableData(1);
              setCurrentPage(1);
            }}
          >
            First
          </button>
          <Pagination
            className="Simple_table_pagination"
            page={currentPage}
            count={rows && Math.ceil(rows.length / 8)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={handleChange}
          />
          <button
            className="table_pagination_button"
            style={{
              color:
                currentPage === (rows && Math.ceil(rows.length / 8))
                  ? "#005EB8"
                  : "",
            }}
            onClick={() => {
              slicedTableData(Math.ceil(rows.length / 8));
              setCurrentPage(Math.ceil(rows.length / 8));
            }}
          >
            Last
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

export default SimpleTable3;
