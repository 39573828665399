import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import VehicleInterestApplication from './vehicle_interests/VehicleInterestApplication';
import PropertyInterestApplication from './property_interests/PropertyInterestApplication';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import { useDispatch } from 'react-redux';
import { setRefresh } from '../../../../../store/usersStore';

function InterestApplications() {
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const { search } = useLocation();
   
    
    
    return (
        <div>
            <h3 className="page_title">Interest Application</h3>
           
            <PropertyInterestApplication />
        </div>
    )
}

export default InterestApplications