import React, { useEffect, useState } from "react";
import "./HomePageHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { updateCollapsedState } from "../../../../store/homePageStore";
import { IconButton } from "@mui/material";
import LanguageToggle from "../../../loginPage/subComponents/languageToggle/LanguageToggle";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ProfileDropdown from "./subComponents/ProfileDropdown";
import NoticationDropdown from "./subComponents/notification/NotificationDropdown";
import { getAllNotifications } from "../../../../api/apiServices/notificationApi";
import Language from "../../../../components/languageswitch/LanguageSwitch";

function HomePageHeader() {
  const userDetails = useSelector((state) => state.mainStore.user_details);
  const [user_Details, setUser_Details] = useState({
    name: "",
    role: "",
  });
  const diaptch = useDispatch();

  useEffect(() => {
    setUser_Details({
      ...user_Details,
      ["name"]: userDetails?.ud_name,
      ["role"]: userDetails?.up_role,
    });
    diaptch(getAllNotifications());
  }, [userDetails]);

  function changeCollapsedState() {
    diaptch(updateCollapsedState());
  }

  return (
    <div className="homePage_header_main_container">
      <div>
        <IconButton onClick={changeCollapsedState}>
          <MenuIcon className="collapse_menu" />
        </IconButton>
      </div>
      <div className="homePage_profile_notification_locale_container">
        <div className="homePage_profile_notification" style={{ gap: 6 }}>
          <div className="homePage_profile">
            <Avatar alt="Remy Sharp" src={``}>
              {user_Details.name.charAt(0)}
            </Avatar>
            <div>
              <p className="homePage_userName">{user_Details.name}</p>
              <p className="homePage_userRole">{user_Details.role}</p>
            </div>
          </div>
          <div className="notification_header">
            <NoticationDropdown />
          </div>
        </div>
        
      </div>
      <div className="header_profile_dropdown">
        
        <div>
          <ProfileDropdown />
        </div>
        <div>
          <NoticationDropdown />
        </div>
      </div>
    </div>
  );
}

export default HomePageHeader;
