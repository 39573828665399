import ResponseHandler from "../../services/ResponseHandler"
import { setAllAgents } from "../../store/agentStore"
import { ApiConfig } from "../apiConfig"

export const getAllAgents=()=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.get('user/agents')
            dispatch(setAllAgents(response.data.data))
        } catch (error) {
            ResponseHandler(error)
        }
    }
}
export const getAgentById=(agent_id)=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.get(`agent/${agent_id}/agent_details`)
           return response
        } catch (error) {
            throw error
        }
    }
}
export const createNewAgent=(values)=>{
    return async(dispatch)=>{
        try{
            const response=await ApiConfig.post('user/create_new_agent',values)
             return response
          }catch(error){
              throw error
          }
    }
}
export const updateAgentDetails=(agent_id,values)=>{
    return async(dispatch)=>{
        try{
            const response=await ApiConfig.put(`agent/${agent_id}/update_agent_details`,values)
             return response
          }catch(error){
              throw error
          }
    }
}
export const updateAgentPassword=(agent_id,values)=>{
    return async(dispatch)=>{
        try{
            const response=await ApiConfig.put(`agent/${agent_id}/update_agent_password`,values)
             return response
          }catch(error){
              throw error
          }
    }
}
export const deleteAgentProfile=(agent_id)=>{
    return async(dispatch)=>{
        try{
            const response=await ApiConfig.delete(`agent/${agent_id}/delete_agent`)
             dispatch( getAllAgents())
             return response
          }catch(error){
              throw error
          }
    }
}
export const GetAuctionDocument=(id,data)=>{
    return  async(dispatch)=>{
      try {
          const response = await ApiConfig.get(`/customer_portal/property/${id}/get_property_sale_aution_file_attachments `,data);
           return response.data.data
        } catch (error) {
           throw error
        }
  }
  }