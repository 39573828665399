import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./SimpleFilter.css";
import IconButton from "@mui/material/IconButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch } from "react-redux";
import {
  filterPropertyTypeByData,
  filterPropertyTypeByDataReset,
  setProperyFilterDatas,
} from "../../store/propertyTypeStore";
import {
  filterVehicleTypeByData,
  filterVehicleTypeByDataReset,
  setVehicleFilterDatas,
} from "../../store/vehicleTypeStore";
import { setRefresh } from "../../store/usersStore";

function SimpleFilter({ id, filterData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);
  const SetFilterData = (value, name) => {
    if (id === "PropertyTypeFilter") {
      dispatch(setProperyFilterDatas({ value, name }));
    }
    dispatch(setRefresh());
  };
  console.log(filterData, "filterData");

  // ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    if (!text) {
      console.log("Empty text, skipping translation");
      return text;
    }

    const currentLang = localStorage.getItem("currentLanguage") || "en";
    console.log("Translating text:", text, "to language:", currentLang);

    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        return data.responseData.translatedText;
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

  const FilterData = async () => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    const isSpanish = currentLang === "es";

    if (isSpanish && filterData.type) {
      const translatedType = await translateText(filterData.type);
      SetFilterData(translatedType, 'type');
      console.log(translatedType, "translatedType");
    }

    if (id === "PropertyTypeFilter") {
      dispatch(filterPropertyTypeByData());
    } else if (id === "VehicleTypeFilter") {
      dispatch(filterVehicleTypeByData());
    }
  };

  const ResetFilter = () => {
    if (id === "PropertyTypeFilter") {
      dispatch(filterPropertyTypeByDataReset());
      setTimeout(() => {
        dispatch(setRefresh());
      }, 50);
    } else if (id === "VehicleTypeFilter") {
      dispatch(filterVehicleTypeByDataReset());
      setTimeout(() => {
        dispatch(setRefresh());
      }, 50);
    }
  };
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px !important",
      fontFamily: "Inter",
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
    },
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".filter_Icon");
      const container = document.querySelector(".filter_field_container");
      const sort = document.querySelector(".simpleFilter_sort_select");
      const autocompletePopper = document.querySelector(
        ".MuiAutocomplete-popper"
      );
      const searchButton = document.querySelector(".filter_actionType_button");
      const textfield = document.querySelector(".simpleFilter_input");

      if (
        !container?.contains(event.target) &&
        !icon?.contains(event.target) &&
        !sort?.contains(event.target) &&
        !autocompletePopper?.contains(event.target) &&
        !searchButton?.contains(event.target) &&
        !textfield?.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  }
  const ModifiedTextField = {
    '& .MuiOutlinedInput-root': {
        fontFamily: 'Poppins',
        fontSize:'15px',
        '& fieldset': {
            borderColor: "rgba(119, 182, 243, 1) !important",
        },
        '&:hover fieldset': {
            borderColor: "rgba(119, 182, 243, 1) !important",
        },
        '&.Mui-focused fieldset': {
            borderColor:"rgba(119, 182, 243, 1) !important",
        },

    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#272727",
    },
};

  return (
    <div>
      <div className="filter_Icon">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("vehicletype_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>
      <div
        id="vehicletype_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div
          id="filter_field_container"
          className="simpleFilter_field_container"
        >
          <div className="simpleFilter_input_add_container">
            <TextField
              autoComplete="off"
              className="simpleFilter_input"
              label=""
              value={filterData.type}
              id="outlined-size-small"
              size="small"
              placeholder={t("Enter type")}
              onChange={(e) => SetFilterData(e.target.value, "type")}
              variant="outlined"
              sx={ModifiedTextField}
            />

            <div style={{ height: 37, mb: 3 }} className="simpleFilter_select">
              <Autocomplete
                className="simpleFilter_sort_select"
                style={{ fontSize: "15px" }}
                sx={{
                  ...inputStyles,
                  width: "200px",
                }}
                size="small"
                disablePortal
                id="combo-box-demo"
                value={
                  // Find the selected option based on the filterData.status value
                  [
                    { label: "All", value: "all" },
                    { label: "Active", value: 1 },
                    { label: "Inactive", value: 0 },
                  ].find((option) => option.value === filterData.status) || { label: "All", value: "all" }
                }
                onChange={(e, value) => {
                  SetFilterData(value ? value.value : "all", "status");  // Set the filter with value only (1, 0 or "all")
                }}
                options={[
                  { label: "All", value: "all" },
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 0 },
                ]}
                getOptionLabel={(option) => option.label}  // Ensure the label is displayed in the dropdown
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Status"
                    InputLabelProps={{ shrink: false }}
                  />
                )}
              />


            </div>

            <div className="filter_actionType_button">
              <Button
                onClick={FilterData}
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  backgroundColor: "#0F62FE",
                  padding: " 5px 25px",
                  borderRadius: "5px",
                }}
              >
                {t("Search")}
              </Button>
              <Button
                onClick={ResetFilter}
                variant="outlined"
                size="small"
                style={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  color: "#005EB8",
                  padding: " 5px 25px",
                  borderRadius: "5px",
                  border: "1px solid #005EB8",
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleFilter;
