import React, { useEffect, useState } from "react";
import "./PropertiesPage.css";
import { ApiConfig } from "../../../../../api/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllProperty,
  updateDefaultChecked,
  updateSelectedTab,
} from "../../../../../store/propertiesStore";
import {
  deletePropery,
  getAllproperties,
} from "../../../../../api/apiServices/propertyApi";
import { setRefresh } from "../../../../../store/usersStore";
import CustomChip from "../../../../../components/customChip/CustomChip";
import ResponseHandler from "../../../../../services/ResponseHandler";
import CustomAdvanceTable from "../../../../../components/customAdvanceTable/CustomAdvanceTable";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import PropertyPageSearchFilter from "./propertyPageSearchFilter/PropertyPageSearchFilter";
import CustomModal from "../../../../../components/customModal/CustomModal";
import SaleStatus from "../../../../../components/saleStatus/SaleStatus";
function PropertiesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const { role } = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [eligiblesale, seteligiblesale] = useState(0);
  const defaultColumnChecked = useSelector(
    (state) => state.propertiesStore.defaultChecked
  );
  const AllProperties = useSelector((state) => state.propertiesStore.property);
  const tableLoading = useSelector(
    (state) => state.propertiesStore.PropertyTaskTableLoading
  );
  // State for pagination
  const [PageNumber, SetPageNumber] = useState(1);
  ;
  const PageSize = 20;
  const [deletePropertyData, setDeletePropertyData] = useState({
    id: "",
    loading: false,
  });

  const updateDefaultCheckedColumn = (data) => {
    dispatch(updateDefaultChecked(data));
  };

  useEffect(() => {
    dispatch(getAllproperties(PageNumber, PageSize));

  }, [PageNumber, PageSize]);



  const UpdateDeleteDetails = (id, loading) => {
    setDeletePropertyData({
      ...deletePropertyData,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const
    handleDelete = (id) => {
      UpdateDeleteDetails(id, true);
      dispatch(deletePropery(id))
        .then((response) => {
          ResponseHandler(response);

          // Properties();
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          UpdateDeleteDetails(id, false);

        });
    };
  const [rowChecked, setRowChecked] = useState(-1);
  const updateRowChecked = (index) => {
    if (index === rowChecked) {
      setRowChecked(-1);
    } else {
      setRowChecked(index);
    }
  };
  const handleClickOpen = (id, sale_status) => {
    dispatch(setRefresh())
    setSelectedId(id)
    seteligiblesale(sale_status);
    setOpen(true);
  };
  console.log(selectedId, "id");

  const handleClose = () => {
    dispatch(setRefresh())
    setOpen(false);
  };
  const Columns = [
    { title: "#", dataIndex: "key", key: "key" },
    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
    },


    { title: "Id", dataIndex: "id", key: "id" },
    { title: "User Id", dataIndex: "user_id", key: "user_id" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Property Sale Price", dataIndex: "sale_price", key: "sale_price" },
    {
      title: "Property Market Price",
      dataIndex: "market_value",
      key: "market_value",
    },
    { title: "Built Up Area", dataIndex: "built_up_area", key: "built_up_area" },
    { title: "Private Treaty", dataIndex: "private_treaty", key: "private_treaty" },
    { title: "Total Area", dataIndex: "total_area", key: "total_area" },
    {
      title: " Property Type",
      dataIndex: "property_type",
      key: "property_type",
    },
    { title: "Seller", dataIndex: "seller", key: "seller" },
    { title: " Possession", dataIndex: "possession", key: "possession" },
    { title: "EMD Type", dataIndex: "emd_type", key: "emd_type" },
    {
      title: "EMD Percentage ",
      dataIndex: "emd_percentage",
      key: "emd_percentage",
    },
    { title: "EMD", dataIndex: "emd", key: "emd" },
    { title: "Location", dataIndex: "location", key: "location" },

    { title: "Application Start Date ", dataIndex: "application_start_date", key: "application_start_date" },
    {
      title: "Application End Date",
      dataIndex: "application_end_date",
      key: "application_end_date",
    },
    { title: "Aution Date", dataIndex: "aution_date", key: "aution_date" },


    {
      title: "Sale Status", dataIndex: "sale_status", key: "sale_status", action: (row) => (
        <p
          className="tagLayout"
          style={{
            border:
              row?.sale_status == "Ready for sale" ? "2px solid #0A8A50" :
                row?.sale_status == "Sold out" ? "2px solid #E63E4B" :
                  row?.sale_status == "Not for sale" ? "2px solid #4ca5fb" : '2px solid #FFB84C',
            color: row?.sale_status == "Ready for sale" ? "#0A8A50" :
              row?.sale_status == "Sold out" ? "#E63E4B" :
                row?.sale_status == "Not for sale" ? "#4ca5fb" : '#FFB84C'
          }}
        >
          {row?.sale_status === "Ready for sale"
            ? "Ready for sale"
            : row.sale_status === "Sold out"
              ? "Sold out"
              : row.sale_status === "Not for sale"
                ? "Not for sale"
                : "Incompleted"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color: row.status === "Completed" ? "green" : "blue",
            width: "100px",
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div className={Privilages && Privilages.url_role === "Agent" ? "property_chipset_agent" : "property_chipset"}>

          {row.status === "Completed" && Privilages && (Privilages.url_role === "SuperAdmin" || Privilages.url_role === "Admin") ? <>
            <CustomChip
              text="Sale"
              bgColor="#228b22"
              color="white"
              onClick={() => {
                handleClickOpen(row.id, row.sale_status);
              }}
            />
          </> : <>
            <CustomChip
              text="Sale"
              disabled={true}
              bgColor="#228b22"
              color="white"
              onClick={() => {
                handleClickOpen(row.id)
              }}
            />
          </>}
        
          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              updateSelectedTab(0);
              const url =
                Privilages && Privilages.url_role === "Agent"
                  ? `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${row.id
                  }/AgentProperty_View`
                  : `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${row.id
                  }/update_properties?tk=${0}&view_edit=${true}`;
              navigate(url);
            }}
          />

          {Privilages &&
            Privilages?.Property &&
            Privilages?.Property.some((item) => item === "Delete") ? (
            <CustomChip
              loading={
                deletePropertyData.id === row.taskId
                  ? deletePropertyData.loading
                  : false
              }
              text="Delete"
              bgColor="#ED5967"
              color="white"
              onClick={() => handleDelete(row.id)}
            />
          ) : null}
          {role === "Agent" ? (
            <>
              {/* <CustomChip
               disabled={JSON.parse(row.isApplied)}
                text="Apply"
                bgColor="#ED5967"
                color="white"
                onClick={() => {
                  navigate(
                    `/${LocalStorageServices.getUserRole()}/home/property/${
                      row.propertyId
                    }/apply?module=property`
                  );
                }}
              /> */}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  console.log(AllProperties, "popopss");


  const datas = () => {
    const result =
      AllProperties &&
      AllProperties.map((item, index) => {
        return {
          key: PageNumber * 5 - (5 - (index + 1)),
          id: item.pd_id,
          user_id: item.pd_user_id,
          property_name: item.pd_name,
          Address: item.pd_address,
          sale_price: item.pd_sale_price,
          market_value: item.pd_market_value,
          possession:item.pd_possession==1?"Physical Possesion": "Symbolic",
          built_up_area: item.pd_built_up_area,
          private_treaty: item.pd_private_treaty==1?"Yes":"No",
          total_area: item.pd_total_area,
          property_type: item.pd_property_type_name,
          seller: item.pd_seller,
          emd_type: item.pd_emd_type,
          emd_percentage: item.pd_emd_percentage,
          location: item.pd_location,
          application_start_date: item.pd_application_start_date,
          application_end_date: item.pd_application_end_date,
          aution_date: item.pd_aution_date,
          sale_status: item.pd_sale_status,
          status: item.pd_status_name,
          eligiblesale: item.eligible_sale,
        };
      })
    return result;
  };
  const Privilages = useSelector((state) => state.userStore.Privilages);





  return (
    <div>
      <div className="Properties_page_header">
        <p className="page_title">Properties</p>
        {Privilages &&
          Privilages?.Property &&
          Privilages?.Property.some((item) => item === "Add") ? (
          <button
            className="add_Properties_button"
            onClick={() => {
              dispatch(updateSelectedTab(0));
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/add_properties`
              );
            }}
          >
            Add Properties
          </button>
        ) : null}
      </div>
      <div>
        <CustomSpin loading={tableLoading}>
          <CustomAdvanceTable
            toolbar={<PropertyPageSearchFilter />}
            columns={Columns}
            rows={datas()}
            defaultCheck={defaultColumnChecked}
            onChangeChecked={updateDefaultCheckedColumn}
            rowChecked={rowChecked}
            onchangeRowchecked={updateRowChecked}
          />
        </CustomSpin>
        
      </div>
      <CustomModal
        open={open}
        handleClose={handleClose}
        body={
          <SaleStatus
            id={selectedId}
            eligiblesale={eligiblesale}
            onClose={handleClose}
          />
        }
        title={'SALE'}
      />
    </div>
  );
}

export default PropertiesPage;
