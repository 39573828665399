import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import VehicleLoanApplication from './vehicle_loan/VehicleLoanApplication';
import PropertyLoanApplication from './property_loan/PropertyLoanApplication';
import { LocalStorageServices } from '../../../../../services/localStorageServices';

function LoanApplications() {
    const navigate = useNavigate()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selected_loan_type = queryParams.get("selected_loan_type");
   

  
    return (
        <div>
            <h3 className="page_title">Loan Application</h3>
            
            <PropertyLoanApplication/>
        </div>
    )
}

export default LoanApplications