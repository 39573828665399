import React, { useEffect, useState } from 'react'
import './lookupStyle.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import BankLookUp from './sub_components/bankLookUp/BankLookUp';
import CurrencyLookUp from './sub_components/currencyLookUp/CurrencyLookUp';
import FuelLookUp from './sub_components/fuelLookUp/FuelLookUp';
import LiquidityLookUp from './sub_components/liquidityLookUp/LiquidityLookUp';
import FinancialLookup from './sub_components/financialLookUp/FinancialLookup';
function Lookup() {
    const { search } = useLocation();
    const [selectedLookup,setselectedLookup]=useState(null)
    const queryParams = new URLSearchParams(search);
    const selected_look_up = queryParams.get("selected_lookup");
    const navigate=useNavigate()
    useEffect(()=>{
      setselectedLookup(selected_look_up)
    },[selected_look_up])
    const lookUpItems = [
      { id: 1, label: 'Banks' },
      { id: 2, label: ' Financial Support' },
     
    ];
  
    const handleLookUpClick = (id) => {
      navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/settings/lookup?selected_lookup=${id}`);
    };
    function UpdateTabData(id){
      switch(id){
        case '1':
          return <BankLookUp/>
        case '2':
          return <FinancialLookup/>
       
        default:
          return <></>
      }
    }
  return (
    <div>
        <div>
          <p className="page_title">Lookup</p>
        </div>
        <div className='lookUp_list_tags'>
        {lookUpItems.map(item => (
          <p className='lookUp_tag' key={item.id}
            onClick={() => handleLookUpClick(item.id)}
            style={{
              color: selectedLookup === `${item.id}` ? 'white' : 'black',
              backgroundColor: selectedLookup === `${item.id}` ? '#0F62FE' : 'white'
            }}>{item.label}</p>
        ))}
      
      </div>
      {UpdateTabData(selectedLookup)}
    </div>
  )
}

export default Lookup