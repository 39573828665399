import React, { useEffect, useState } from "react";
import "./HomePageSlider.css";
import { motion } from "framer-motion";
import Logo from "../../../../assets/ProductLogoPng.png";
import SliderMenu from "../sliderMenu/SliderMenu";
import Dashboard_Icon from "../../../../assets/DashboardIcon.png";
import Property_Icon from "../../../../assets/PropertyIcon.png";
import User_Staff_Icon from "../../../../assets/User_StaffIcon.png";
import Agent_Icon from "../../../../assets/AgentIcon.png";

import Notification_Icon from "../../../../assets/NotificationIcon.png";
import Setting_Icon from "../../../../assets/SettingIcon.png";
import Logout_Icon from "../../../../assets/LogoutIcon.png";
import Customer_portal_Icon from "../../../../assets/CustomerPortal.png";
import ResponsiveLogo from "../../../../assets/BankReality_ResponsiveLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateCollapsedState } from "../../../../store/homePageStore";
import CloseIcon from "@mui/icons-material/Close";
import { VisibilityHelper } from "../helpers/VisibilityHelper";
import { LocalStorageServices } from "../../../../services/localStorageServices";
import { useParams } from "react-router-dom";
function HomePageSlider() {
  const { t } = useTranslation();
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { role } = useParams();
  useEffect(() => {
    if (window.innerWidth < 1000) {
      VisibilityHelper("homePageSlider_main_container", IsCollapsed);
    } else {
      VisibilityHelper("homePageSlider_main_container", true);
    }
  }, [IsCollapsed, windowWidth]);
  const dispatch = useDispatch();

   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };
  
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);

  return (
    <>
      <div
        className="homePageSlider_main"
        style={{
          display: window.innerWidth <= 1000 && IsCollapsed ? "block" : "none",
        }}
        onClick={() => dispatch(updateCollapsedState())}
      />

      <motion.div
        id="homePageSlider_main_container"
        className="homePageSlider_main_container"
        initial={{ width: "280px" }} // Initial width
        animate={
          windowWidth > 1000
            ? { width: IsCollapsed ? "80px" : "280px" }
            : { width: "280px" }
        }
        transition={{ duration: 0.1 }}
      >
        {window.innerWidth > 1000 && IsCollapsed ? (
          <img
            src={ResponsiveLogo}
            alt="BankReality"
            className="slider_Responsive_logo"
          />
        ) : (
          <img src={Logo} alt="BankReality" className="slider_logo" />
        )}
        <div
          className="homePageSlider_resposiveClose"
          onClick={() => {
            dispatch(updateCollapsedState());
          }}
        >
          <CloseIcon className="sidebar_CloseIcon" />
        </div>
        <div
          className="homePageSlider_menu_section"
          style={{
            overflowY: !IsCollapsed || windowWidth <= 1000 ? "auto" : "visible",
          }}
        >
          <SliderMenu
            isPermitted={true}
            menukey="1"
            icon={Dashboard_Icon}
            text={t("Dashboard")}
            initialRoute={`/admin_portal/${LocalStorageServices.getUserRole()}/home/dashboard`}
          
            collapsed={IsCollapsed}
          
          />
          {role !== "Runner" ? (
            <>
             
              <SliderMenu
                isPermitted={true}
                menukey="3"
                icon={Property_Icon}
                text={t("Property")}
                initialRoute=""
                collapsed={IsCollapsed}
                subMenu={[
                  {
                    isPermitted:
                      Privilages &&
                      Privilages?.Property &&
                      Privilages?.Property.some((item) => item === "View"),
                    menukey: "3.1",
                    text: t("Properties"),
                    subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`,
                  },
                  {
                    isPermitted:
                      Privilages &&
                      Privilages?.Property_Type &&
                      Privilages?.Property_Type.some((item) => item === "View"),
                    menukey: "3.2",
                    text: t("Property Type"),
                    subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/property_type`,
                  },
                ]}
              />
            </>
          ) : (
            <></>
          )}
          
          {role !== "Runner" && role !== "Agent" && role !== "Customer_Portal_Agent" ? (
            <>
              <SliderMenu
                isPermitted={true}
                menukey="5"
                icon={User_Staff_Icon}
                text={t("User/Staff")}
                initialRoute=""
                collapsed={IsCollapsed}
                subMenu={[
                  {
                    isPermitted:
                      Privilages &&
                      Privilages?.User &&
                      Privilages?.User.some((item) => item === "View"),
                    menukey: "5.1",
                    text: t("Users"),
                    subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/user_staff/users`,
                  },
                  {
                    isPermitted:
                      Privilages &&
                      Privilages?.Role &&
                      Privilages?.Role.some((item) => item === "View"),
                    menukey: "5.2",
                    text: t("Roles"),
                    subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/user_staff/roles`,
                  },
                  {
                    isPermitted:
                      Privilages &&
                      Privilages?.Privilage &&
                      Privilages?.Privilage.some((item) => item === "View"),
                    menukey: "5.3",
                    text: t("Privilages"),
                    subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/user_staff/privilages`,
                  },
                ]}
              />
              <SliderMenu
                isPermitted={
                  Privilages &&
                  Privilages?.Agent &&
                  Privilages?.Agent.some((item) => item === "View")
                }
                menukey="6"
                icon={Agent_Icon}
                text={t("Agents")}
                initialRoute={`/admin_portal/${LocalStorageServices.getUserRole()}/home/agent/agents`}
                collapsed={IsCollapsed}
              />
             
            </>
          ) : (
            <></>
          )}

          
          <SliderMenu
            isPermitted={true}
            menukey="11"
            icon={Notification_Icon}
            text={t("Notification")}
            initialRoute={`/admin_portal/${LocalStorageServices.getUserRole()}/home/notification`}
            collapsed={IsCollapsed}
          />
           <SliderMenu
            isPermitted={
              Privilages &&
              Privilages?.Customer_Portal &&
              Privilages?.Customer_Portal.some((item) => item === "View")
            }
            menukey="14"
            icon={Customer_portal_Icon}
            text={t("Customer Portal")}
            initialRoute=""
            collapsed={IsCollapsed}
            subMenu={[
              {
                isPermitted:true,
                menukey: "14.1",
                text: t("Customers"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/customers`,
              },
              {
                isPermitted:true,
                menukey: "14.2",
                text: t("Interest Applications"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/interest_applications`,
              },
              {
                isPermitted:true,
                menukey: "14.3",
                text: t("Loan Applications"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/loan_applications`,
              },
              {
                isPermitted:true,
                menukey: "14.4",
                text: t("Enquiries"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/enquires`,
              },
              {
                isPermitted:true,
                menukey: "14.5",
                text: t("Contact us"),
                subRoute:`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/contact_us`,
              },
              {
                isPermitted:true,
                menukey: "14.6",
                text: t("Testimonials"),
                subRoute:`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/testimonials`,
              },
              
              
            ]}
            
          />
          <SliderMenu
            isPermitted={
              Privilages &&
              Privilages?.Setting &&
              Privilages?.Setting.some((item) => item === "View")
            }
            menukey="12"
            icon={Setting_Icon}
            text={t("Settings")}
            initialRoute=""
            collapsed={IsCollapsed}
            subMenu={[
              {
                isPermitted:true,
                menukey: "12.1",
                text: t("Lookup"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/settings/lookup?selected_lookup=1`,
              },
              {
                isPermitted:true,
                menukey: "12.2",
                text: t("Profile"),
                subRoute: `/admin_portal/${LocalStorageServices.getUserRole()}/home/settings/profile`,
              },
            ]}
            
          />
         
          <SliderMenu
            isPermitted={true}
            menukey="13"
            icon={Logout_Icon}
            text={t("Logout")}
            initialRoute="/admin_portal/login"
            collapsed={IsCollapsed}
          />
        </div>
      </motion.div>
    </>
  );
}

export default HomePageSlider;
