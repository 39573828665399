import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./ChangePassword.css";
import { updateUserDetails_user } from "../../../../../../api/apiServices/userApis";
import { useDispatch } from "react-redux";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { updateUserPassword_user } from "../../../../../../api/apiServices/userApis";
import { SuccessToast ,ErrorToast} from "../../../../../../toasts/Toasts";
function ChangePassword({ onclose, userDetails }) {
  const dispatch = useDispatch();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    up_password: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string().required("New password is required"),
    up_password: Yup.string()
      .required("Confirm new password is required"),
  });
  let formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.newPassword !== values.up_password) {
        ErrorToast("Password doesn't match");
        return;
      }
  
      dispatch(updateUserPassword_user(values))
        .then((response) => {
          ResponseHandler(response);
          onclose(false);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  });
  
  return (
  
    <div className="update_property_task_assigne_Form">

      <form id="userProfileform" onSubmit={formik.handleSubmit}>
        <div className="userProfile_form_row">
          <div>
            <label className="userProfile_form_label">Current Password</label>
            <CustomTextField
              name="oldPassword"
              placeholder={"Current Password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.oldPassword}
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
        </div>
        <div className="userProfile_form_row">
          <div>
            <label className="userProfile_form_label">New Password</label>
            <CustomTextField
              name="newPassword"
              placeholder={"New Password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
        </div>

        <div className="userProfile_form_row">
          <div>
            <label className="userProfile_form_label">
              Confirm New Password
            </label>
            <CustomTextField
              name="up_password"
              placeholder={"Confirm Password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.up_password}
              error={
                formik.touched.up_password && Boolean(formik.errors.up_password)
              }
              helperText={
                formik.touched.up_password && formik.errors.up_password
              }
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
        </div>
        <div className="Special_Chracters">
          <p className="characters">Atleast 8 Characters</p>
          <p className="characters">Upper and Lower Case Letters (A-Z a-z)</p>
          <p className="characters">Numbers 0 to 9</p>
          <p className="characters">!@#$%^&* Special Characters are valid</p>
        </div>
        <div className="userProfile_form_action_button">
          <FormButton
            id="userProfileCreateSubmit"
            type="submit"
            form="userProfileform"
            variant="contained"
            // loading={loading}
            text={"Submit"}
          />
          <FormButton
            id="userProfileCreateCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => {
              onclose(false);
            }}
          />
        </div>
      </form>
</div>
    
  );
}

export default ChangePassword;
