
import React, { useState } from "react";
import "./Dashboard.css";
import {  useSelector } from "react-redux";
import SuperAdminDashboard from "./dashboards/super_admin_dashboard/SuperAdminDashboard";
import RunnerDashboard from "./dashboards/runner_dashboard/RunnerDashboard";
import AgentDashboard from "./dashboards/agent_dashboard/AgentDashboard";

function Dashboard() {
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
    {Privilages && (Privilages.url_role === "SuperAdmin" || Privilages.url_role === "Admin") ? <>
     <SuperAdminDashboard/>
    </>:Privilages && Privilages.url_role === "Runner"?<>
     <RunnerDashboard/>
    </>:Privilages && (Privilages.url_role === "Agent" || Privilages.url_role === "Customer_Portal_Agent") ?
    <AgentDashboard/>
    :<></>}
    </div>
  );
}

export default Dashboard;
