import React, { useEffect, useState } from "react";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import "./PropertyDetailsForm.css";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import FormButton from "../../../../../../components/formButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { getPropertyType } from "../../../../../../api/apiServices/propertyTypeApi";
import { getAllpossessions } from "../../../../../../api/apiServices/statusApi";
import { getAllEmdTypes } from "../../../../../../api/apiServices/statusApi";

import {
  getPropertyDetails,
  updatePropertyDetails,
  createNewPropertyDetails,
} from "../../../../../../api/apiServices/propertyApi";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import { getAllApplicationOwnerNames } from "../../../../../../api/apiServices/applicationOwnerApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RiFileEditFill } from "react-icons/ri";
import map_icon from "../../../../../../assets/map_icon.png";
import CustomMap from "../../../vehicle/vehicleFormPage/map/CustomMap";
import { transform } from "ol/proj";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import { pdf } from "@react-pdf/renderer";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
function PropertyDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property_id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Status, SetStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [possessionList, setPosessionList] = useState([]);
  const [EmdTypeList, setEmdTypeList] = useState([]);
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [SellerNames, SetSellerNames] = useState([]);
  const [PropertList, setPropertyList] = useState([]);

  const propertyTypeData = useSelector(
    (state) => state.propertyTypeStore.PropertyTypes
  );

  const [lattitudeLongitude, SetlattitudeLongitude] = useState("");
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log(position)
        const lattitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        SetlattitudeLongitude([longitude, lattitude]);
      });
    }
    if (property_id) {
      dispatch(getPropertyDetails(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [property_id]);
  useEffect(() => {
    AllPropertyType()
    AllSellerNames();
    AllStatus();
    dispatch(getPropertyType());
    getAllpossessions()
      .then((response) => {
        setPosessionList(AllPossessions(response));
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    getAllEmdTypes()
      .then((response) => {
        setEmdTypeList(AllEmdTypes(response));
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  function AllSellerNames() {
    dispatch(getAllApplicationOwnerNames())
      .then((response) => {
        const data = response.map((item) => {
          return {

            label: item.aonl_name,
            value: item.aonl_id,
          };
        });
        SetSellerNames(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllEmdTypes(EmdTypeData) {
    if (EmdTypeData && EmdTypeData.length > 0) {
      const data = EmdTypeData.map((item) => {
        return {
          label: item.pel_name,
          value: item.pel_id,
        };
      });
      return data;
    } else {
      return [];
    }
  }
  function AllStatus() {
    dispatch(getAllStatus())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        SetStatus(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllPossessions(PossessionsData) {
    if (PossessionsData && PossessionsData.length > 0) {
      const data = PossessionsData.map((item) => {
        return {
          label: item.ppl_name,
          value: item.ppl_id,
        };
      });
      return data;
    } else {
      return [];
    }
  }
  function AllPropertyType(propertyData) {
    if (propertyData && propertyData.length > 0) {
      const data = propertyData
        // .filter((item) => item.ptl_status === "active")
        .map((item) => {
          return {
            label: item.ptl_type,
            value: item.ptl_id,
          };
        });
      return data;
    } else {
      return [];
    }
  }

  function calculateEmdPercentage(emdValue) {
    const totalMarketValue = formik.values.market_value || 1; // Avoid division by zero
    return ((emdValue / totalMarketValue) * 100).toFixed(2);
  }
  const initialValues = {

    name: "",
    sale_price: "",
    market_value: "",
    address: "",
    built_up_area: "",
    private_treaty: "",
    total_area: "",
    property_type: "",
    seller: "",
    possession: "",
    emd_type: "",
    emd_percentage: "",
    emd: "",
    location: "",
    longitude: "",
    lattitude: "",
    important_note: "",
    additional_informations: "",
    near_by_informations: "",
    application_start_date: "",
    application_end_date: "",
    aution_date: "",
    status: "",
    
  };


  const validationSchema = Yup.object({
    name: Yup.string().required("property Name is required"),
    sale_price: Yup.number()
    .required("Sale Price is required")
    .test("sale-price-check", "Sale Price cannot exceed Market Value", function (value) {
      const { market_value } = this.parent; // Access market_value from form values
      if (value && market_value && parseFloat(value) > parseFloat(market_value)) {
        return false; // Return false if sale_price exceeds market_value
      }
      return true; // Return true if validation passes
    }),
  market_value: Yup.number()
    .required("Property Market Value is required")
    .typeError("Market Value must be a number"),
    address: Yup.string().required("Address is required"),
    built_up_area: Yup.string().required("Built-up Area is required"),
    private_treaty: Yup.string().required("Private Treaty is required"),
    total_area: Yup.string().required("Total Area is required"),
    property_type: Yup.string().required("Property type is required"),
    seller: Yup.string().required("Seller is required"),
    possession: Yup.string().required("Possession is required"),
    emd_type: Yup.string().required("EMD Type is required"),
    emd: Yup.string().required("EMD is required"),
    location: Yup.string().required("Location is required"),
    important_note: Yup.string().required("Important Note is required"),
    additional_informations: Yup.string().required("Additional Information is required"),
    near_by_informations: Yup.string().required("Near by Information is required"),
    aution_date: Yup.string().required("Auction Date is required"),
    status: Yup.string().required("Status is required"),
    application_start_date: Yup.string().required("Start Date is required"),
    application_end_date: Yup.string()
      .required("End Date is required"),
      emd_percentage: Yup.string().test('emd-percentage-check', "EMD Percentage is required", function(value) {
        const { emd_type } = this.parent; // Get the value of emd_type from the parent object
        if (emd_type !== '1' && !value) {
          return this.createError({ message: "EMD Percentage is required" }); // Return an error if emd_type is not '1' and value is empty
        }
        return true; // Return true if validation passes
      }),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `pd_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
    if (data.pd_longitude && data.pd_lattitude) {
      SetlattitudeLongitude([data.pd_longitude, data.pd_lattitude]);
    }

  }
  const calculateEmd = (salePrice, percentage) => {
    const price = parseFloat(salePrice) || 0;
    const perc = parseFloat(percentage) || 0;
    return perc > 0 ? (price * perc) / 100 : 0; // Calculate EMD if percentage is greater than 0
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values["longitude"] = lattitudeLongitude[0];
      values["lattitude"] = lattitudeLongitude[1];
      setLoading(true);
      if (property_id) {
        dispatch(updatePropertyDetails(property_id, values))
          .then((response) => {
            ResponseHandler(response);
            if (iSViewAndEdit) {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${1}&view_edit=${true}`
              );
            } else {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${1}`
              );
            }
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(createNewPropertyDetails(values))
          .then((response) => {
            ResponseHandler(response);
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${response.data.data.pd_id}/update_properties?tk=${1}`
            );
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };
  const UpdateLocationCordinates = (data) => {
    console.log(data)
    SetlattitudeLongitude(data);
  };

  return (
    <div>
      <div className="PropertyDetails_form_main_Container">
        {Privilages &&
          Privilages?.Property &&
          Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
            <div
              className="Property_detailsfrom_edit_details"
              onClick={() => SetIsview(false)}
            >
              <RiFileEditFill />
              <p>Edit</p>
            </div>
          )
          : null}
        <form id="PropertyDetailsform" onSubmit={formik.handleSubmit}>
          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Property Details</p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Property Name
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="name"
                    placeholder="Property Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Property Sale Price
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="sale_price"
                    placeholder="Enter Property Sale Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sale_price}
                    error={
                      formik.touched.sale_price &&
                      Boolean(formik.errors.sale_price)
                    }
                    helperText={
                      formik.touched.sale_price &&
                      formik.errors.sale_price
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Property Market Price
                  </p>

                  <CustomTextField
                    name="market_value"
                    disabled={Isview}
                    placeholder=" Enter Market Price "
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.market_value}
                    error={
                      formik.touched.market_value &&
                      Boolean(formik.errors.market_value)
                    }
                    helperText={
                      formik.touched.market_value && formik.errors.market_value
                    }
                  />
                </div>
                {/* {formik.values.emd_type === 2 && ( */}
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">EMD Percentage</p>
                    <CustomTextField
                      disabled={Isview || formik.values.emd_type === 1}
                      name="emd_percentage"
                      placeholder="Enter EMD Percentage"
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue("emd_percentage", value);
                        const emdValue = calculateEmd(formik.values.market_value, value);
                        formik.setFieldValue("emd", emdValue);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.emd_percentage}
                      error={
                        formik.touched.emd_percentage && 
                        Boolean(formik.errors.emd_percentage) && 
                        formik.values.emd_type !== 1 // Only show error if emd_type is not 1
                      }
                      helperText={
                        formik.touched.emd_percentage && 
                        formik.errors.emd_percentage && 
                        formik.values.emd_type !== 1 // Only show helper text if emd_type is not 1
                          ? formik.errors.emd_percentage 
                          : ''
                      }
                    />
                  </div>
                {/* )} */}
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    {" "}
                    Total Area
                  </p>
                  <CustomTextField
                    name="total_area"
                    disabled={Isview}
                    placeholder="Enter Total Area"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_area}
                    error={
                      formik.touched.total_area &&
                      Boolean(formik.errors.total_area)
                    }
                    helperText={
                      formik.touched.total_area && formik.errors.total_area
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Property Type
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="property_type"
                    placeholder="Select property type"
                    options={AllPropertyType(propertyTypeData)}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "property_type",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      AllPropertyType(propertyTypeData).find(
                        (type) => type.value === formik.values.property_type
                      ) || null
                    }
                    // isOptionEqualToValue={(option, value) =>
                    //   option.value.toString() === value.value.toString()
                    // }
                    error={
                      formik.touched.property_type &&
                      Boolean(formik.errors.property_type)
                    }
                    helperText={
                      formik.touched.property_type &&
                      formik.errors.property_type
                    }
                  />
                </div>

                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    {" "}
                    Possession
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="possession"
                    placeholder="Select  possession"
                    options={possessionList}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "possession",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      possessionList.find(
                        (item) => item.value === formik.values.possession
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value.toString() === value.value.toString()
                    }
                    error={
                      formik.touched.possession &&
                      Boolean(formik.errors.possession)
                    }
                    helperText={
                      formik.touched.possession && formik.errors.possession
                    }
                  />
                </div>


                <div className="vehicle_taskform_field_container-date-main">
                  <div className="vehicle_taskform_field_container">
                    <p className="vehicle_taskform_fields_labels">Start Date</p>
                    <CustomDatePicker
                      name="application_start_date"
                      disabled={Isview}
                      onChange={(date) => {
                        formik.setFieldValue("application_start_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.application_start_date).format("DD-MM-YYYY")}
                      error={formik.touched.application_start_date && Boolean(formik.errors.application_start_date)}
                      helperText={formik.touched.application_start_date && formik.errors.application_start_date}
                    />

                  </div>
                  <div className="vehicle_taskform_field_container">
                    <p className="vehicle_taskform_fields_labels">End Date</p>
                    <CustomDatePicker

                      minDate={dayjs(formik.values.application_start_date,"DD-MM-YYYY")}
                      name="application_end_date"
                      disabled={Isview}
                      onChange={(date) => {
                        formik.setFieldValue("application_end_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.application_end_date).format("DD-MM-YYYY")}

                      error={formik.touched.application_end_date && Boolean(formik.errors.application_end_date)}
                      helperText={formik.touched.application_end_date && formik.errors.application_end_date}
                    />
                  </div>
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Status</p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="status"
                    placeholder="Select status"
                    options={Status}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "status",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      Status.find(
                        (status) => status.value === formik.values.status
                      ) || null
                    }
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">Address</p>
                    <CustomTextArea
                      name="address"
                      disabled={Isview}
                      placeholder="Enter Address"
                      onChange={formik.handleChange}
                      maxRows={3.9}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">EMD Type</p>
                    <CustomAutoComplete
                      disabled={Isview}
                      name="emd_type"
                      placeholder="Select EMD Type"
                      options={EmdTypeList}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("emd_type", newValue ? newValue.value : "");
                        if (newValue && newValue.value === 'percentage') {
                          // Reset the EMD percentage when switching to percentage
                          formik.setFieldValue("emd_percentage", '');
                          formik.setFieldValue("emd", calculateEmd(formik.values.sale_price, '')); // Calculate EMD based on sale price
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        EmdTypeList.find((item) => item.value === formik.values.emd_type) || null
                      }
                      error={formik.touched.emd_type && Boolean(formik.errors.emd_type)}
                      helperText={formik.touched.emd_type && formik.errors.emd_type}
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">EMD</p>
                    <CustomTextField
                      disabled={Isview || formik.values.emd_type === 2}
                      name="emd"
                      placeholder="Enter EMD"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.emd}
                      error={formik.touched.emd && Boolean(formik.errors.emd)}
                      helperText={formik.touched.emd && formik.errors.emd}
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Built-Up Area
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="built_up_area"
                      placeholder="Enter Built-Up Area"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.built_up_area}
                      error={
                        formik.touched.built_up_area &&
                        Boolean(formik.errors.built_up_area)
                      }
                      helperText={
                        formik.touched.built_up_area &&
                        formik.errors.built_up_area
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Private Treaty
                    </p>
                    <CustomAutoComplete
                      disabled={Isview}
                      name="private_treaty"
                      options={[
                        { label: "Yes", value: 1 },
                        { label: "No", value: 2 },
                      ]}
                      placeholder="Select Private Treaty"
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "private_treaty",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        // Ensure that the current value is an object matching one of the options
                        formik.values.private_treaty
                          ? {
                            label:
                              formik.values.private_treaty === 1
                                ? "Yes"
                                : "No",
                            value: formik.values.private_treaty,
                          }
                          : null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      error={
                        formik.touched.private_treaty &&
                        Boolean(formik.errors.private_treaty)
                      }
                      helperText={
                        formik.touched.private_treaty &&
                        formik.errors.private_treaty
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">seller</p>
                    <CustomAutoComplete
                      disabled={Isview}
                      name="seller"
                      placeholder="Select seller "
                      options={SellerNames}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "seller",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        SellerNames.find(
                          (seller) =>
                            seller.value === formik.values.seller
                        ) || null
                      }
                      error={
                        formik.touched.seller && Boolean(formik.errors.seller)
                      }
                      helperText={formik.touched.seller && formik.errors.seller}
                    />
                  </div>


                  <p className="PropertyDetailsform_fields_labels">
                    Location ({lattitudeLongitude.toString()})
                  </p>
                  <div className="PropertyDetailsform_with_extra_actions" >
                    <CustomTextField
                      name="location"
                      disabled={Isview}
                      placeholder="Enter location"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                    <div
                      onClick={() => {
                        if (!Isview) {
                          handleClickOpen();
                        }
                      }}
                    >
                      <img src={map_icon} />
                    </div>
                  </div>



                  <div className="PropertyDetailsform_field_container" style={{ marginTop: "15px" }}>
                    <p className="PropertyDetailsform_fields_labels">
                      Auction Date
                    </p>
                    <CustomDatePicker
                      name={"aution_date"}
                      disabled={Isview}
                      onChange={(date) => {
                        formik.setFieldValue("aution_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.aution_date).format("DD-MM-YYYY")}
                      error={
                        formik.touched.aution_date &&
                        Boolean(formik.errors.aution_date)
                      }
                      helperText={
                        formik.touched.aution_date && formik.errors.aution_date
                      }
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Property Important Note</p>
            <div className="PropertyDetailsform_fields_textarea">
              <div className="PropertyDetailsform_field_container">
                <p className="PropertyDetailsform_fields_labels">
                  {" "}
                  Important Note{" "}
                </p>
                <CustomTextArea
                  name="important_note"
                  disabled={Isview}
                  placeholder="Enter Important Note"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxRows={5}
                  value={formik.values.important_note}
                  error={
                    formik.touched.important_note &&
                    Boolean(formik.errors.important_note)
                  }
                  helperText={
                    formik.touched.important_note &&
                    formik.errors.important_note
                  }
                />
              </div>

            </div>
          </div>
          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend"> Near By Information</p>
            <div className="PropertyDetailsform_fields_textarea">
              <div className="PropertyDetailsform_field_container">
                <p className="PropertyDetailsform_fields_labels">
                  Near By Information
                </p>
                <CustomTextArea
                  name="near_by_informations"
                  disabled={Isview}
                  maxRows={5}
                  placeholder="Enter  Near By Information"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.near_by_informations}
                  error={
                    formik.touched.near_by_informations &&
                    Boolean(formik.errors.near_by_informations)
                  }
                  helperText={
                    formik.touched.near_by_informations &&
                    formik.errors.near_by_informations
                  }
                />
              </div>
            </div>
          </div>
          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Additional Information</p>
            <div className="PropertyDetailsform_fields_textarea">

              <div className="PropertyDetailsform_field_container">
                <p className="PropertyDetailsform_fields_labels">
                  Additional Information
                </p>
                <CustomTextArea
                  name="additional_informations"
                  disabled={Isview}
                  placeholder="Enter Additional Information"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.additional_informations}
                  maxRows={5}
                  error={
                    formik.touched.additional_informations &&
                    Boolean(formik.errors.additional_informations)
                  }
                  helperText={
                    formik.touched.additional_informations &&
                    formik.errors.additional_informations
                  }
                />
              </div>
            </div>
          </div>
          {!Isview && (
            <div className="PropertyDetailsform_action">
              <FormButton
                id="PropertyDetailsformCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                  );
                }}
              />
              <FormButton
                id="PropertyDetailsformCreateSubmit"
                type="submit"
                form="PropertyDetailsform"
                variant="contained"
                loading={loading}
                text="Save & Continue"
              />
            </div>
          )}
        </form>
        <CustomMap
          locationCordinates={lattitudeLongitude}
          open={open}
          handleClose={handleClose}
          UpdateLocationCordinates={UpdateLocationCordinates}
        />
      </div>
    </div>
  );
}

export default PropertyDetailsForm;
