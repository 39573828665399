import React from 'react'
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './CustomDatePicker.css'
import dayjs from 'dayjs';
function CustomDatePicker({
    name,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    FormHelperTextProps,
    disabled,
    minDate
  }) {
    const inputStyles = {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '8px 14px!important',
          fontSize: '15px!important',
          height: '25px!important',
        },
      };
    
      const outlinedInputStyles = {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0F62FE !important',
          },
        },
      };

      return (
        <div style={{position:'relative'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
    minDate={minDate}
    disabled={disabled}
    onChange={(date) => {
        // Ensuring date format in "DD-MM-YYYY"
        onChange(date ? date.format("DD-MM-YYYY") : "");
    }}
    format="DD-MM-YYYY"
    className='custom-datepicker-input-class'
    fullWidth
    name={name}
    autoComplete="off"
    size="small"
    onBlur={onBlur}
    // Convert to dayjs object if value is not null
    value={value ? dayjs(value, "DD-MM-YYYY") : null}
    slotProps={{
        textField: {
            helperText: helperText || '',
            error: !!error,
            FormHelperTextProps: {
                style: { marginLeft: 0, position: 'absolute', top: '36px' },
            },
        },
    }}
    sx={{
        ...inputStyles,
        ...outlinedInputStyles,
    }}
/>
        </LocalizationProvider>
        </div>
        
      );
}

export default CustomDatePicker